import React, { useEffect } from "react";
import { parseParamUrls } from "@utils/index";
import useCustomState from "@hook/useCustomState";
import DicomViewer from "@components/DicomViewer";
import datahubServiceProvider from "@data-access/datahub-service-provider";

const PacsView = () => {
  const { ris_exam_id, service_id } = parseParamUrls();
  const [state, setState] = useCustomState({});

  useEffect(() => {
    if (ris_exam_id && service_id) {
      console.log("CALL API");
      datahubServiceProvider.getPacsAsset({ ris_exam_id, service_id })
        .then((res) => {
          setState({
            data: res.data,
            dataStudy: res.data.studyList,
          });
        })
        .catch((err) => {
          console.log("ERROR", err?.message);
        });
    }
  }, []);
  return (
    <div
      id="AppDicomViewer"
      class="app-dicomviewer"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div class="viewerDialogs">{/* {{> AnnotationDialogs }} */}</div>
      <DicomViewer dataStudy={state.dataStudy} />
    </div>
  );
};

export default PacsView;
