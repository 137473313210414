import { ContainerOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import { AiDemoIcon, ConvertCardBlack, ProfileIcon } from "@assets/svg";
import Button from "@components/Elements/Buttons";
import { CustomTypography } from "@components/Elements/Typography";
import { MenuModeEnum } from "@layouts/trade/header";
import { MenuVerticalComponents } from "@layouts/trade/header/menuVerStyled";
import { Stack } from "@mui/material";
import { AddressTooltip } from "@pages/trade/components/AddressTooltip";
import { IconData } from "@pages/trade/components/constants";
import { colorMineShaft950 } from "@src/styles/modules/colors";
import { CollapseIcon, IviIcon } from "@svg";
import { getLengthAddress } from "@utils";
import { canHover, formatPrice, getTooltipDisableProps } from "@utils/index";
import { Button as AntdButton, Drawer } from "antd";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { formatUnits } from "viem";
import { erc20ABI, useAccount, useContractRead, useToken } from "wagmi";
import { HeaderMenuWrapper } from "./styled";
import { generateDefaultOpenKeys, generateMenu, getTradeHeaderItems } from "./utils";

const CONNECT_STATUSES = {
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  DISCONNECTING: "DISCONNECTING",
};

const getConnectButtonStatues = (connectionStatus) => {
  switch (connectionStatus) {
    case CONNECT_STATUSES.CONNECTED:
      return {
        text: "Disconnect",
        disabled: false,
      };
    case CONNECT_STATUSES.DISCONNECTING:
      return {
        text: "Disconnecting...",
        disabled: true,
      };
    default:
      return {
        text: "Disconnected",
        disabled: true,
      };
  }
};

const DrawerHeader = (props) => {
  const disconnect = useDispatch().contracts?.disconnect;
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { updateTab } = props;
  const isAdmin = useSelector((state) => state?.consensus?.isAdmin);

  const [connectionStatus, setConnectionStatus] = useState(
    CONNECT_STATUSES.CONNECTED
  );

  const onDisconnect = async () => {
    setConnectionStatus(CONNECT_STATUSES.DISCONNECTING);
    await disconnect({ history });
    setConnectionStatus(CONNECT_STATUSES.DISCONNECTED);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleChangeCollapse = (key) => {};

  const handleChangeTab = (tabVal) => {
    updateTab({ activeNavItem: tabVal });
  };

  const MyWalletItemList = [
    {
      Icon: ProfileIcon,
      text: "Profile",
      onClick: () => {
        props.ownerGetCollectedData();
        history.push("/data-hub/profile");
      },
      showCond: true,
    },

    {
      Icon: ConvertCardBlack,
      text: "Deposit",
      onClick: () => {
        history.push("/data-hub/deposit");
      },
      showCond: true,
    },
    {
      Icon: MoneyCollectOutlined,
      text: "Token management",
      onClick: () => {
        history.push("/token-management");
      },
      showCond: true,
    },
    {
      Icon: ContainerOutlined,
      text: "Token allocation",
      onClick: () => {
        history.push("/token-allocation");
      },
      showCond: true,
    },
    {
      Icon: AiDemoIcon,
      text: "AI Imaging & Diagnostics",
      showCond: true,
      onClick: () => {
        history.push("/data-hub/ai-demo-page");
      },
    },
  ];
  const { currentContractProperties } = useSelector((state) => state.contracts);
  const { address } = useAccount();
  const { data } = useToken({
    address: currentContractProperties?.address,
    enabled: currentContractProperties?.address,
  });
  const { data: balanceOfStableToken } = useContractRead({
    address: currentContractProperties?.address,
    abi: erc20ABI,
    functionName: "balanceOf",
    args: [address],
    enabled: address && currentContractProperties?.address,
    watch: true,
  });
  const tokenBalance = balanceOfStableToken
    ? Number(formatUnits(balanceOfStableToken, 18))?.toFixed(2)
    : 0;

  const { text, disabled } = getConnectButtonStatues(connectionStatus);

  const menus = getTradeHeaderItems({ history });

  const tooltipDisableProps = getTooltipDisableProps(!canHover());

  const defaultOpenKeys = generateDefaultOpenKeys(menus, pathname);
  
  return (
    <HeaderMenuWrapper>
      <AntdButton type="primary" onClick={showDrawer} className="menu-ipad">
        <CollapseIcon />
      </AntdButton>
      <Drawer
        placement="right"
        onClose={onClose}
        open={visible}
        destroyOnClose
        bodyStyle={{ padding: "0px" }}
        title={
          <div className="d-flex justify-content-space-between drawer-user-info__container">
            <div className="content-left">
              <div>
                <AddressTooltip
                  {...tooltipDisableProps}
                  address={address}
                  getLengthAddress={getLengthAddress(
                    address,
                    window.innerWidth
                  )}
                />
              </div>

              <div className="drawer-header-title-balance">
                <IviIcon className="mr-1" width={18} height={18} />
                <CustomTypography fontWeight={600}>
                  {balanceOfStableToken ? formatPrice(tokenBalance) : 0}
                  <CustomTypography component="span" fontWeight={500}>
                    {` ${data?.symbol}`}
                  </CustomTypography>
                </CustomTypography>
              </div>
              <Button
                variant="outline"
                shape="rounded"
                fullSized
                onClick={onDisconnect}
                disabled={disabled}
              >
                {text}
              </Button>
            </div>
          </div>
        }
      >
        <Stack direction="column" justifyContent="space-between" height="100%">
          <MenuVerticalComponents.NavMenu
            mode={MenuModeEnum.Inline}
            className="ant-menu-sub-bg-grey"
            defaultOpenKeys={defaultOpenKeys}
          >
            {generateMenu({
              data: menus,
              variant: true,
              path: `${pathname}${search}`,
              ParentComponent: MenuVerticalComponents,
              subMenuTextProps: {
                color: colorMineShaft950,
              },
              subMenuProps: {
                color: colorMineShaft950,
                hoverColor: colorMineShaft950,
              },
              menuItemProps: {
                color: colorMineShaft950,
                hoverColor: colorMineShaft950,
              },
            })}
          </MenuVerticalComponents.NavMenu>
          <div className="footer-drawer">
            <div className="footer-drawer-icon">
              {Object.keys(IconData).map((key, index) => {
                let Icon = IconData[key].icon;
                return (
                  <a href={IconData[key].link} key={index} target="_blank">
                    <Icon />
                  </a>
                );
              })}
            </div>
            <div className="policy d-flex justify-content-space-between">
              <div className="text-italic">IVIRSE © 2022</div>
              <div className="d-flex-column ">
                <Link to={"/policy"}>Privacy Policies</Link>
                <p>Terms of Service</p>
              </div>
            </div>
          </div>
        </Stack>
      </Drawer>
    </HeaderMenuWrapper>
  );
};

const mapStateToProps = ({
  contracts: {
    address,
    balance,
    currentNetworkName,
    type,
    currentContractProperties,
    symbol,
  },
}) => ({
  address,
  balance,
  currentNetworkName,
  type,
  currentContractProperties,
  symbol,
});

const mapDispatchToProps = ({
  contracts: { connect, disconnect, mint },
  admin: { updateData },
  datasharing: { ownerGetCollectedData },
}) => ({
  connect,
  disconnect,
  mint,
  updateTab: updateData,
  ownerGetCollectedData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerHeader);
