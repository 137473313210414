import clientUtils from "@utils/client-utils";
import { Manager } from "socket.io-client";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const ws = {
  state: {
    socketTokenManage: null,
    socketCommunity: null,
  },
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    createSocketManager: () => {
      const socketManager = new Manager(SOCKET_URL, {
        transports: ["websocket", "polling"],
        reconnection: true,
        query: {
          token: clientUtils.auth.substring(7),
        },
      });
      dispatch.ws.updateData({ socketManager });
    },
  }),
};

export default ws;
