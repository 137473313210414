export const SOCKET_NAMESPACES = {
  STAKING: {
    ADMIN: "/staking/admin",
    USER: "/staking/user",
    COMMON: "/staking",
  },
};

export const SOCKET_CHANNELS = {
  STAKING: {
    ADMIN: {
      METRICS: {
        MASTER_SMC_FOR_REWARD: "metrics:masterSmcForReward",
        STAKING_OVERVIEW: "metrics:stakingOverview",
        TOTAL_CIRCULATING_SUPPLY: "metrics:totalCirculatingSupply",
      },
      TOKEN_STATISTICS: "metrics:tokenStatistics",
      PERIOD: {
        CREATED: "period:created",
        DELETED: "period:deleted",
      },
      PROPOSAL: {
        CREATED: "proposal:created",
        UPDATED: "proposal:updated",
        DELETED: "proposal:deleted",
        REQUESTED: "proposal:requested",
      },
      VOTE: {
        UPDATED: "vote:updated",
      },
      TOKEN: {
        UPDATED: "token:updated",
      },
    },
    USER: {},
    COMMON: {
      POOL: {
        CREATED: "pool:created",
        UPDATED: "pool:updated",
      },
    },
  },
};

export const POOL_PAYLOAD_TYPES = {
  STATUS_UPDATED: "status_updated",
  STATISTICS_UPDATED: "statistics_updated",
  VOTE_UPDATED: "vote_updated",
};