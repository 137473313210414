import { css } from "styled-components";

export default css`
  input,
  input:focus,
  select {
    outline: none;
    box-shadow: none;
  }

  button,
  button:active,
  button:visited,
  button:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }

  textarea,
  input[type="text"] :not(.ignore-global-style),
  input[type="number"],
  input[type="email"],
  input[type="password"],
  input[type="button"],
  input[type="submit"],
  input[type="search"],
  select {
    appearance: none;
    -webkit-appearance: none;
    outline-color: transparent;
    outline: 0;
    border: 0px;
  }
`;
