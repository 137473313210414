import { Stack } from "@mui/material";
import { colorMineShaft950 } from "@src/styles/modules/colors";
import { Dropdown } from "antd";
import styled, { createGlobalStyle } from "styled-components";

export const NotificationBellWrapper = styled(Dropdown)`
  /* z-index: -1; */
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 28px;
    height: 28px;
  }
`;
export const GlobalStyle = createGlobalStyle`
  .ant-popover  {
    z-index: 1000;
  }
  .child-popover{
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    svg{
      *{
        fill: #2B2B2B;
      }
      
    }
    &:hover{
    background: #F5F5F5;
    border-radius: 5px;
  }
  }
   .hover-border{
    cursor: pointer;
    &:hover{
      background: #C6ABE7;
      border-radius: 50%;
     
      circle{
        stroke:#C6ABE7;
      }
      
    }

   }

   .hover-border--2{
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: #C6ABE7;
    border-radius: 50%;
     
    circle{
      stroke:#C6ABE7;
    }

   }
   .p-3-line{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
   }
`;

export const ChangeLanguageWrapper = styled(Dropdown)`
  cursor: pointer;
`;

export const DropDownGlobalStyled = createGlobalStyle`
  .ant-dropdown {
    z-index: 1050;
  }
  .ant-dropdown-menu {
    border-radius: 5px;
  }
  .ant-dropdown-menu-title-content {
    color: #6E6E6E;
    :hover{
      color:#2B2B2B;
    }
  }
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
    .ant-dropdown-menu-title-content{
      color: #6E5AC3;
      font-family: 'Bai Jamjuree';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      }
   
  }
`;

export const StackSettingItemStyled = styled(Stack)`
  svg {
    width: 20px;
    height: 20px;
    opacity: 0.8;
    path {
      stroke: ${colorMineShaft950};
    }
  }
  &:hover {
    svg {
      opacity: 1;
    }
    p {
      font-weight: 600;
    }
  }
`;
