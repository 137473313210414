export const size = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
  xxxxl: 1680,
};

const subtractAmount = 0.02;

export const device = {
  maxMobile: `@media only screen and (max-width: ${
    size.sm - subtractAmount
  }px)`,
  maxSmallTablet: `@media only screen and (max-width: ${
    size.md - subtractAmount
  }px)`,
  maxTablet: `@media only screen and (max-width: ${
    size.lg - subtractAmount
  }px)`,
  maxDesktop: `@media only screen and (max-width: ${
    size.xl - subtractAmount
  }px)`,
  maxLargeDesktop: `@media only screen and (max-width: ${
    size.xxl - subtractAmount
  }px)`,
  maxExtraLargeDesktop: `@media only screen and (max-width: ${
    size.xxxl - subtractAmount
  }px)`,
  max2ExtraLargeDesktop: `@media only screen and (max-width: ${
    size.xxxxl - subtractAmount
  }px)`,

  minMobile: `@media only screen and (min-width: ${size.sm}px)`,
  minSmallTablet: `@media only screen and (min-width: ${size.md}px)`,
  minTablet: `@media only screen and (min-width: ${size.lg}px)`,
  minDesktop: `@media only screen and (min-width: ${size.xl}px)`,
  minLargeDesktop: `@media only screen and (min-width: ${size.xxl}px)`,
  minExtraLargeDesktop: `@media only screen and (min-width: ${size.xxxl}px)`,
  min2ExtraLargeDesktop: `@media only screen and (min-width: ${size.xxxxl}px)`,
};
