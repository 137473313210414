import { BellIcon } from "@assets/svg";
import datahubServiceProvider from "@data-access/datahub-service-provider";
import useCustomState from "@hook/useCustomState";
import clientUtils from "@utils/client-utils";
import { getLengthAddress } from "@utils/index";
import { Badge } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuRender from "./MenuRender";
import "./style.scss";
import { NotificationBellWrapper, StackSettingItemStyled } from "./styled";
import styled from "styled-components";
import { colorMineShaft950 } from "@src/styles/modules/colors";
import { CustomTypography } from "@components/Elements/Typography";

const NotificationsBell = () => {
  const [state, setState] = useCustomState({
    open: false,
    data: [],
    totalUnreadNotification: 0,
  });
  const { open, data, totalUnreadNotification } = state;
  const setOpen = (boolean) => {
    setState({ open: boolean });
  };

  const buyer = useSelector((state) => state?.datasharing?.buyer);

  const { buyerGetCollectedData } = useDispatch().datasharing;
  const listNotifications = useMemo(
    () =>
      data.map((item) => {
        const spl = item.content?.split(" ");
        item.content = spl
          .map((content) =>
            content.length > 20 ? getLengthAddress(content) : content
          )
          .join(" ");

        return item;
      }),
    [data]
  );

  useEffect(() => {
    if (open) {
      getNotifications();
      countUnreadNotification();
    }
  }, [open]);

  useEffect(() => {
    if (clientUtils.auth) {
      countUnreadNotification();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (buyer) {
      buyerGetCollectedData();
    }
  }, [buyer]);

  const getNotifications = () => {
    datahubServiceProvider
      .getNotification({ page: 0, size: 999999 })
      .then((res) => {
        if (res.code === 200) {
          setState({ data: res.data });
        } else {
          throw Error(res.message);
        }
      })
      .catch((error) => {});
  };

  const countUnreadNotification = () => {
    datahubServiceProvider
      .countUnreadNotification()
      .then((res) => {
        if (res.code === 200) {
          setState({ totalUnreadNotification: res.data });
        } else {
          throw Error(res.message);
        }
      })
      .catch((error) => {});
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const toggleRead = (id) => (event) => {
    event.stopPropagation();
    datahubServiceProvider
      .toggleRead(id)
      .then((res) => {
        if (res.code === 200) {
          getNotifications();
        } else {
          throw Error(res.message);
        }
      })
      .catch((error) => {});
  };

  const handleDeleteNotification = (id) => (event) => {
    event.stopPropagation();
    datahubServiceProvider
      .deleteNotification(id)
      .then((res) => {
        if (res.code === 200) {
          getNotifications();
        } else {
          throw Error(res.message);
        }
      })
      .catch((error) => {});
  };

  const handleReadAllNotification = (event) => {
    event.stopPropagation();
    datahubServiceProvider
      .readAll()
      .then((res) => {
        if (res.code === 200) {
          getNotifications();
        } else {
          throw Error(res.message);
        }
      })
      .catch((error) => {});
  };
  const handleDeleteAllNotification = (event) => {
    event.stopPropagation();
    datahubServiceProvider
      .deleteAll()
      .then((res) => {
        if (res.code === 200) {
          getNotifications();
        } else {
          throw Error(res.message);
        }
      })
      .catch((error) => {});
  };

  return (
    <NotificationBellWrapper
      open={open}
      trigger={["click"]}
      placement="bottomRight"
      dropdownRender={() => (
        <MenuRender
          dataList={listNotifications}
          numUnread={totalUnreadNotification}
          handleUpdateStatus={toggleRead}
          handleDelete={handleDeleteNotification}
          handleReadAll={handleReadAllNotification}
          handleDeleteAll={handleDeleteAllNotification}
        />
      )}
      overlayClassName="ivi-menu-dropdown__wrapper"
      onOpenChange={handleOpen}
    >
      <CustomStackSettingItemStyled
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Badge dot={totalUnreadNotification > 0}>
          <BellIcon />
        </Badge>
        <CustomTypography>Notifications</CustomTypography>
      </CustomStackSettingItemStyled>
    </NotificationBellWrapper>
  );
};

export default NotificationsBell;

export const CustomStackSettingItemStyled = styled(StackSettingItemStyled)`
  svg {
    path {
      stroke: inherit;
      fill: ${colorMineShaft950};
    }
  }
`;
