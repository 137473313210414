import { createTheme } from "@mui/material/styles";
import { BaiJamjuree } from "@src/styles/modules/fonts";
import { size } from "@src/styles/modules/media-query";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: size.sm,
      md: size.md,
      lg: size.lg,
      xl: size.xl,
      xxl: size.xxl,
      xxxl: size.xxxl,
      xxxxl: size.xxxxl,
    },
  },
  typography: {
    fontFamily: BaiJamjuree,
  },
});
