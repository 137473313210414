import {
  colorAmethyst600,
  colorAquaSpring100,
  colorAquaSpring50,
  colorDoveGray500,
  colorGallery100,
  colorMineShaft950,
  colorShamrock400,
  colorSilver400,
  colorSilverTree400,
  whiteColor,
} from "@src/styles/modules/colors";
import styled, { css } from "styled-components";

const colors = {
  violet: {
    bg: `radial-gradient(96.92% 1534.99% at 95.38% 91.84%, #915FCD 7.32%, #AE5297 100%)`,
    text: `${whiteColor}`,

    hover: `radial-gradient(96.92% 1534.99% at 95.38% 91.84%, #915FCD 0%, #AE5297 50.52%)`,

    borderColor: `${colorAmethyst600}`,
    borderSize: "1px",
    borderStyle: "solid",

    disableBg: `${colorGallery100}`,
    disableText: `${colorSilver400}`,
  },
  green: {
    bg: `${colorSilverTree400}`,
    text: `${whiteColor}`,
  },
  black: {
    bg: `${colorMineShaft950}`,
    text: `${whiteColor}`,
  },
  dark: {
    bg: `${colorDoveGray500}`,
    text: `${whiteColor}`,

    hover: "#646464",

    borderColor: `${colorDoveGray500}`,
    borderSize: "1px",
    borderStyle: "solid",
  },
  light: {
    bg: `${colorDoveGray500}`,
    text: `${whiteColor}`,

    borderColor: `${whiteColor}`,
    borderSize: "1px",
    borderStyle: "solid",
  },
  aquaSpring: {
    bg: colorAquaSpring50,
    text: colorShamrock400,

    hover: colorAquaSpring100,
  },
};

const sizes = {
  sm: {
    padding: "4px 10px",
    fontSize: "14px",
  },
  md: {
    padding: "6px 30px",
    fontSize: "16px",
  },
  lg: {
    padding: "12px 45px",
    fontSize: "18px",
  },
};

const shapes = {
  circle: "50%",
  rounded: "40px",
};

const disabledStyles = (props) => {
  return props.$loading
    ? css`
        opacity: 0.8;
        .anticon-loading {
          margin-right: 8px;
        }
      `
    : css`
        opacity: 0.5;
        background: ${(props) => colors[props.color].disableBg};
        color: ${(props) => colors[props.color].disableText};
        border-color: ${(props) => colors[props.color].disableBg};
        svg {
          path {
            fill: ${(props) => colors[props.color].disableText};
          }
        }
      `;
};

const commonStyles = css`
  &,
  &:focus,
  &:active {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    border-radius: ${(props) => shapes[props.shape] || "8px"};

    &:disabled {
      cursor: not-allowed;
      ${disabledStyles}
    }
  }

  font-size: ${(props) => sizes[props.size].fontSize};
  padding: ${(props) => sizes[props.size].padding};
  width: ${(props) => (props.fullSized ? "100%" : "")};
  margin: ${(props) => (props.margin ? props.margin : "inherit")};
`;

const solidStyles = css`
  background: ${(props) => colors[props.color].bg};
  color: ${(props) => colors[props.color].text};
  border-radius: ${(props) => colors[props.color].borderRadius};

  svg {
    path {
      fill: ${(props) => colors[props.color].text};
    }
  }

  &:hover:not(&:disabled) {
    background: ${(props) => colors[props.color].hover};
  }
`;

const outlineStyles = css`
  &,
  &:focus,
  &:active {
    background: transparent;
    color: ${(props) => colors[props.color].borderColor};
    border-radius: ${(props) => colors[props.color].borderRadius};
    border: ${(props) =>
      `${colors[props.color].borderSize} ${colors[props.color].borderStyle} ${
        colors[props.color].borderColor
      }`};

    svg {
      path {
        fill: ${(props) => colors[props.color].borderColor};
      }
    }

    &:hover:not(&:disabled) {
      background: ${(props) => colors[props.color].bg};
      color: ${(props) => colors[props.color].text};
      border: ${(props) =>
        `${colors[props.color].borderSize} ${colors[props.color].borderStyle} ${
          colors[props.color].bg
        }`};

      svg {
        path {
          fill: ${(props) => colors[props.color].text};
        }
      }
    }
  }
`;

const getVariantStyles = (props) => {
  switch (props.variant) {
    case "solid":
      return solidStyles;
    case "outline":
      return outlineStyles;
    default:
      return solidStyles;
  }
};

export const ButtonStyled = styled.button`
  ${commonStyles}
  ${getVariantStyles}
`;

export const Icon = styled.span`
  margin-right: ${(props) => (props.$endIcon ? "0" : "8px")};
  margin-left: ${(props) => (props.$startIcon ? "0" : "8px")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
