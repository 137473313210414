import clientUtils from "@utils/client-utils";
import baseProvider from "./base-provider";
const baseUrl = "/admin";
const adminProvider = {
  ...baseProvider(baseUrl),
  login(data) {
    let url = `${baseUrl}/login`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "POST",
          url,
          isUseServiceUrl: true,
          body: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  logout() {
    let url = `${baseUrl}/logout`;
    return new Promise((resolve, reject) => {
      // if (clientUtils.auth) {
        clientUtils
          .requestApi({
            ignoreAuth: false,
            methodType: "POST",
            url,
            isUseServiceUrl: true,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((e) => reject(e))
          .finally(() => {
            clientUtils.auth = "";
          });
      // } else {
      //   resolve("Not have auth");
      // }
    });
  },
};

export default adminProvider;
