import React from "react";
import * as cornerstone from "cornerstone-core";
import { viewportUtils } from "./lib/viewerbase/viewportUtils";

const t = (_, a) => a;

const tools = [
  // {
  //   id: "stackScroll",
  //   title: t("dicomviewer", "Stack Scroll"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-bars fa-lg",
  // },
  // {
  //   id: "wwwc",
  //   title: t("dicomviewer", "Levels"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-sun-o fa-lg",
  // },
  // {
  //   id: "wwwcRegion",
  //   title: t("dicomviewer", "Levels ROI"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-square fa-lg",
  // },
  {
    id: "zoomIn",
    title: t("dicomviewer", "Zoom In"),
    classes: "imageViewerTool",
    iconClasses: "fa fa-search fa-lg",
    action: viewportUtils.zoomIn,
  },
  {
    id: "zoomOut",
    title: t("dicomviewer", "Zoom Out"),
    classes: "imageViewerTool",
    iconClasses: "fa fa-search fa-lg",
    action: viewportUtils.zoomOut,
  },
  // {
  //   id: "pan",
  //   title: t("dicomviewer", "Pan"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-arrows fa-lg",
  // },
  {
    id: "invert",
    title: t("dicomviewer", "Invert"),
    classes: "imageViewerCommand",
    iconClasses: "fa fa-adjust fa-lg",
    action: viewportUtils.invert,
  },
  {
    id: "flipH",
    title: t("dicomviewer", "Flip H"),
    classes: "imageViewerCommand",
    iconClasses: "fa fa-ellipsis-h fa-lg",
    action: viewportUtils.flipH,
  },
  {
    id: "flipV",
    title: t("dicomviewer", "Flip V"),
    classes: "imageViewerCommand",
    iconClasses: "fa fa-ellipsis-v fa-lg",
    action: viewportUtils.flipV,
  },
  {
    id: "rotateL",
    title: t("dicomviewer", "Rotate L"),
    classes: "imageViewerCommand",
    svgClasses: "svgContent rotate-left",
    action: viewportUtils.rotateL,
  },
  {
    id: "rotateR",
    title: t("dicomviewer", "Rotate R"),
    classes: "imageViewerCommand",
    svgClasses: "svgContent rotate-right",
    action: viewportUtils.rotateR,
  },
  // {
  //   id: "magnify",
  //   title: t("dicomviewer", "Magnify"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-circle",
  // },
  // {
  //   id: "length",
  //   title: t("dicomviewer", "Length"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-arrows-v fa-lg",
  // },
  // {
  //   id: "angle",
  //   title: t("dicomviewer", "Angle"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-angle-left fa-lg",
  // },
  // {
  //   id: "dragProbe",
  //   title: t("dicomviewer", "Probe"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-dot-circle-o fa-lg",
  // },
  // {
  //   id: "ellipticalRoi",
  //   title: t("dicomviewer", "Elliptical ROI"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-circle-o fa-lg",
  // },
  // {
  //   id: "rectangleRoi",
  //   title: t("dicomviewer", "Rectangle ROI"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-square-o fa-lg",
  // },
  // {
  //   id: "annotate",
  //   title: t("dicomviewer", "Annotate"),
  //   classes: "imageViewerTool",
  //   iconClasses: "fa fa-long-arrow-down fa-lg",
  // },
  // {
  //   id: "clearTools",
  //   title: t("dicomviewer", "Clear"),
  //   classes: "imageViewerCommand",
  //   iconClasses: "fa fa-trash fa-lg",
  //   action: viewportUtils.clearTools,
  // },
  {
    id: "reset",
    title: t("dicomviewer", "Reset"),
    classes: "imageViewerCommand",
    iconClasses: "fa fa-undo fa-lg",
    action: viewportUtils.resetViewport,
  },
  // {
  //   id: "toggleCaptureImageDialog",
  //   title: t("dicomviewer", "Capture"),
  //   classes: "imageViewerCommand",
  //   iconClasses: "fa fa-camera fa-lg",
  //   action: viewportUtils.toggleCaptureImageDialog,
  // },
];
// const zoomIn = () => {
//   const element = document.getElementById("imageViewerViewport");
//   if (!element) {
//     return;
//   }

//   const viewport = cornerstone.getViewport(element);
//   const scaleIncrement = 0.15;
//   const maximumScale = 10;
//   viewport.scale = Math.min(viewport.scale - scaleIncrement, maximumScale);
//   cornerstone.setViewport(element, viewport);
// };
function Toolbar() {
  return (
    <div id="toolbar" class="toolbar">
      {/* <div class="toggleSeriesPanel">
        <div
          id="toggleSeriesPanelButton"
          tabindex="1"
          class="toolbarSectionButton roundedButton toggleSeriesPanelButton {{#if seriesPanelOpen}}active{{/if}} {{classes}}"
          title="{{ToggleSeriesPanelText}}"
        >
          <div class="svgContainer">
            <div class="svgContent series-panel"></div>
          </div>
          <div class="buttonLabel">
            <span>Series</span>
          </div>
        </div>
      </div> */}

      <div class="toolbarSectionTools">
        {tools.map((item, idx) => (
          <div
            id={idx}
            tabindex="1"
            class="toolbarSectionButton {{#if toolActive}}active{{/if}} {{classes}}"
            title={item.title}
            onClick={item.action}
          >
            <div class="svgContainer">
              {item.svgClasses ? (
                <div class={item.svgClasses}></div>
              ) : (
                <i class={item.iconClasses}></i>
              )}
            </div>
            <div class="buttonLabel">
              <span>{item.title}</span>
            </div>
          </div>
        ))}

        <div class="moreTools js-more-tools">
          <div class="toolbarSectionButton notTool">
            <div class="svgContainer">
              <i class="fa fa-chevron-down"></i>
            </div>
            <div class="buttonLabel">
              <span>More</span>
            </div>
          </div>
        </div>
      </div>

      {/* <a class="button-close js-close-viewer" title="{{CloseViewerText}}">
        <i class="fa fa-times fa-lg"></i>
      </a> */}
    </div>
  );
}

export default Toolbar;
