import { createGlobalStyle } from "styled-components";
import customAntd from "./custom-antd";
import { colorRed700 } from "./modules/colors";
import { BaiJamjuree } from "./modules/fonts";
import reset from "./modules/reset";
import { zIndex } from "@src/styles/modules/z-index";
import { customScrollbar } from "@src/styles/utils";

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${customAntd};

  .error-label {
    color: ${colorRed700};
    font-size: 14px;
    font-family: ${BaiJamjuree};
    font-weight: 400;
    display: block;
    margin-top: 6px;
  }

  .react-select {
    &__menu-portal {
      z-index: ${zIndex.dropdown} !important;
    }

    &__menu-list {
      ${customScrollbar(8)}
    }
  }
`;
