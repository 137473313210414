import { useCallback, useState } from "react";

const useCustomState = (defaultState = {}) => {
  const [state, _setState] = useState(defaultState);

  const setState = useCallback((data = {}) => {
    _setState((prev) => ({
      ...prev,
      ...data,
    }));
  }, []);

  const resetState = (newData = {}) => {
    _setState({ ...defaultState, ...newData });
  };

  return [state, setState, resetState];
};

export default useCustomState;
