// import "./components"
import React, { useState, useEffect, useRef } from "react";
import Hammer from "hammerjs";
import dicomParser from "dicom-parser";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/cornerstoneWADOImageLoader.js";
import * as cornerstoneWEBImageLoader from "cornerstone-web-image-loader/dist/cornerstoneWebImageLoader.js";
import * as cornerstoneTools from "cornerstone-tools";
import useCustomState from "@hook/useCustomState";
import { convertUrlPacs } from "@utils/index";
// Externals
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWEBImageLoader.external.cornerstone = cornerstone;
cornerstoneWEBImageLoader.external.dicomParser = dicomParser;

cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
// // CodeSandbox live updates components in an odd way.
// // We do this to protect ourselves from duplicate initializations
// if (!cornerstoneWADOImageLoader.initialized) {
//   // WadoImageLoader Registration/Config
// //   const config = {
// //     // webWorkerPath: "/codecs/cornerstoneWADOImageLoaderWebWorker.js",
// //     // taskConfiguration: {
// //     //     decodeTask: {
// //     //       codecsPath: "/codecs/cornerstoneWADOImageLoaderCodecs.js",
// //     //     },
// //     // },
// //   };
//   cornerstoneWADOImageLoader.webWorkerManager.initialize();
//   cornerstoneWADOImageLoader.initialized = true;
// }

export function DicomViewer({ url, style }) {
  const canvasRef = useRef();
  const [state, setState] = useCustomState({});

  useEffect(() => {
    if (url) {
      const urlArr = url.split(".");
      const extension = urlArr[urlArr.length - 1];

      global.cornerstoneTools = cornerstoneTools;
      cornerstone.enable(canvasRef.current, {
        renderer: "webgl",
      });
      const synchronizer = new cornerstoneTools.Synchronizer(
        "cornerstonenewimage",
        cornerstoneTools.updateImageSynchronizer
      );
      const dcmUrl = convertUrlPacs(url);

      cornerstone.loadImage(dcmUrl).then((image) => {
        cornerstone.displayImage(canvasRef.current, image);

        // set the stack as tool state
        // synchronizer.add(canvasRef.current);
        // cornerstoneTools.addStackStateManager(canvasRef.current, ["stack"]);
        //   cornerstoneTools.addToolState(
        //     canvasRef.current,
        //     "stack",
        //     canvasStack2
        //   );
        //   cornerstoneTools.StackScrollMouseWheelTool.activate(canvas2.current);
      });
      //   } else {
      //     setState({
      //       imgUrl: url
      //         .replace("wadouri:/", "http://pacsapp.mediplus.vn:741/")
      //         .replace("weburi:/", "http://pacsapp.mediplus.vn:741/"),
      //     });
      //   }
    }
  }, [url]);

  return <div style={style} ref={canvasRef}></div>;
  //   state.imgUrl ? (
  //     <img style={style} src={state.imgUrl} />
  //   ) : (
  //     <div style={style} ref={canvasRef}></div>
  //   );
}
