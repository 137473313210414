import { MenuHorizontalComponents } from "@layouts/trade/header/menuHozStyled";
import { device } from "@src/styles/modules/media-query";
import { Menu } from "antd";
import styled from "styled-components";

export const NavSubMenuText = styled(MenuHorizontalComponents.NavSubMenuText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavMenu = styled(Menu)`
  &.ant-menu {
    background-color: transparent;
    border: none;
  }
  ${device.maxDesktop} {
    > .ant-menu-item:last-child {
      display: none !important;
    }
  }
`;

export const NavSubMenu = styled(MenuHorizontalComponents.NavSubMenu)``;

export const NavItem = styled(MenuHorizontalComponents.NavItem)`
  .ant-menu-title-content {
    overflow: visible !important;
  }
`;

export const MenuVerticalComponents = {
  NavItem,
  NavMenu,
  NavSubMenu,
  NavSubMenuText,
};
