import { BellIcon, DotIcon, Eye, ThreeDot, Trash } from "@assets/svg";
import { DatahubNotiTypeEnum } from "@constants/index";
import { whiteColor } from "@src/styles/modules/colors";
import { getNotiContent, getNotiTitle, strings } from "@utils/index";
import { Dropdown } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GlobalStyle } from "./styled";

const MenuRender = ({
  dataList,
  numUnread,
  handleReadAll,
  handleDeleteAll,
  handleDelete ,
  handleUpdateStatus 
}) => {
  const buyerListAcceptRequest = useSelector(
    (state) => state.datasharing.buyerListAcceptRequest
  );
  const history = useHistory();

  const handleClickNotificationByType = (type, notificationData) => (e) => {
    e.stopPropagation();
    switch (type) {
      case DatahubNotiTypeEnum.WHEN_USER_RECEIVED_REQUEST_ACCESS_TO_DATA_BY_ANY_PURCHASER:
        history.push({
          pathname: "/data-hub/profile/requests",
          state: {
            notiData: notificationData,
          },
        });
        return;
      case DatahubNotiTypeEnum.WHEN_RECEIVED_DATA_REPORT_RESULT:
        history.push(
          `/data-hub/asset-detail/${notificationData?.detail?.assetCId}`
        );
        return;
      case DatahubNotiTypeEnum[
        "WHEN_THE_REPORT_RESULTS_CONFIRM_A_DATA_ERROR_(REPORT_SUCCESSFULLY)"
      ]:
        history.push(
          `/data-hub/asset-detail/${notificationData?.detail?.assetCId}`
        );
        return;
      case DatahubNotiTypeEnum[
        "WHEN_THE_REPORT_RESULTS_CONFIRM_THERE’S_NO_DATA_ERROR_(REPORT_FAILED)"
      ]:
        history.push(
          `/data-hub/asset-detail/${notificationData?.detail?.assetCId}`
        );
        return;
      case DatahubNotiTypeEnum.WHEN_OWNER_ACCEPTED_REQUEST_ACCESS_DATA:
        const collectData = buyerListAcceptRequest?.find(
          (item) => item.dataCid === notificationData?.detail?.assetCId
        );
        if (collectData) {
          history.push(
            `/data-hub/asset-detail/${notificationData?.detail?.assetCId}?role=buyer&requestId=${collectData.requestId}`
          );
        }
        return;
      case DatahubNotiTypeEnum.WHEN_OWNER_REJECTED_REQUEST_ACCESS_DATA:
        history.push(
          `/data-hub/asset-detail/${notificationData?.detail?.assetCId}`
        );
        return;
      case DatahubNotiTypeEnum.WHEN_USER_DEPOSIT_TO_SMART_CONTRACT_BALANCE_SUCCESSFULLY:
        history.push("/data-hub/deposit");
        return;
      case DatahubNotiTypeEnum.WHEN_USER_WITHDRAW_FROM_SMART_CONTRACT_BALANCE_TO_WALLET_SUCCESSFULLY:
        history.push("/data-hub/deposit");
        return;
      case DatahubNotiTypeEnum.WHEN_USER_LISTING_DATA_SUCCESSFULLY:
        history.push({
          pathname: `/data-hub/asset-detail/${notificationData?.detail?.assetCId}`,
          search: `?role=${
            address === notificationData?.detail?.toUser ? "owner" : "buyer"
          }`,
        });
        return;
      default:
        return;
    }
  };
  return (
    <div className="ivi-menu-dropdown">
      <GlobalStyle />

      <div className="dropdown-menu__header mb-4 d-flex justify-content-space-between">
        <div>
          {strings("noti.notification")} <span>{numUnread}</span>
        </div>
        <Dropdown
          trigger="hover"
          dropdownRender={() => (
            <DropdownContentStyled>
              {[
                {
                  icon: <Eye />,
                  text: strings("noti.markAllAsRead"),
                  onClick: handleReadAll,
                },
                {
                  icon: <Trash />,
                  text: strings("noti.deleteAll"),
                  onClick: handleDeleteAll,
                },
              ].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex justify-content-space-around child-popover"
                    onClick={item.onClick}
                    style={{ gap: "20px" }}
                  >
                    <div className="d-flex align-items-center">{item.icon}</div>
                    <div>{item.text}</div>
                  </div>
                );
              })}
            </DropdownContentStyled>
          )}
        >
          <ThreeDot className="hover-border" />
        </Dropdown>{" "}
      </div>
      <div className="dropdown-menu__body">
        {!isEmpty(dataList) ? (
          dataList.map((item, index) => (
            <div
              className="notification-item d-flex gap-10"
              key={index}
              onClick={handleClickNotificationByType(item.detail.type, item)}
            >
              <div className="d-flex">
                {" "}
                <div className="bell-icon">
                  <BellIcon />
                </div>
                <div className="notification-item__body">
                  <h3
                    className={`${item?.detail?.status ? "" : "unread-noti"}`}
                  >
                    {getNotiTitle(item.detail?.type)}
                  </h3>
                  <p className="p-3-line">{getNotiContent(item.detail)}</p>
                  <p>
                    {moment(item?.detail?.createdAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-10 hover-display">
                <>
                  {" "}
                  <Dropdown
                    trigger="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    dropdownRender={() => (
                      <DropdownContentStyled>
                        {[
                          {
                            icon: <Eye />,
                            text: strings("noti.markAsReadOrUnread"),
                            onClick: handleUpdateStatus(item?.detail?._id),
                          },
                          {
                            icon: <Trash />,
                            text: strings("noti.delete"),
                            onClick: handleDelete(item?.detail?._id),
                          },
                        ].map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex justify-content-space-around child-popover"
                              onClick={(e) => {
                                item?.onClick(e);
                              }}
                              style={{ gap: "20px" }}
                            >
                              <div className="d-flex align-items-center">
                                {item.icon}
                              </div>
                              <div>{item.text}</div>
                            </div>
                          );
                        })}
                      </DropdownContentStyled>
                    )}
                  >
                    <ThreeDot className="hover-border--2" />
                  </Dropdown>{" "}
                </>
              </div>{" "}
              {!item?.detail?.read && (
                <div className="d-flex align-items-center">
                  <DotIcon color={"#0A9921"} />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="empty">
            <img alt="" src={require("@images/emptyNotification.png")} />
            <p className="text-center">{strings("noti.noNotificationsYet")}</p>
            <p className="text-center">
              {strings("noti.wellNotifyYouWhenSomethingArrives")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default MenuRender;

const DropdownContentStyled = styled.div`
  background-color: ${whiteColor};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  padding: 10px;
`;
