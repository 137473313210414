import storeInstance from "../store-instance";

const storeName = "datahub";

const datahub = {
  ...storeInstance({
    storeName: storeName,
    initStore: {
      collected: {},
      liked: {},
      history: {},
      tradingStatus: null,
      params: {
        page: 0,
        size: 9,
        sort: "",
      },
      statisticParams: {
        owner: null,
      },
      type: 1,
      listDataFromBC: [],
      sortMarket: [],
      // paramDatahub: {
      //   sort: [],
      //   size: 12,
      // },
    },
    customEffect: ({ dispatch }) => ({
      setAmountDeposit: ({ amount }) => {
        dispatch.datahub.updateData({ amountDeposit: amount });
      },
    }),
  }),
};

export default datahub;
