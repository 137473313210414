import { Modal } from "antd";
import styled from "styled-components";

export const SpineFractureWrapper = styled.div`
  --font-color-dark: #61707d;
  --font-color-light: #f5fbef;
  --header-color: #9d69a3;

  --page-color-green: #40f99b;
  --page-color-gray: #61707d;
  --page-color-pink: #9d69a3;
  --page-color-white: #f5fbef;
  --page-color-red: #e85d75;

  --header-height: 100px;

  min-height: 100vh;
  background-color: var(--page-color-white);
  .container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  header {
    background-color: var(--header-color);
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    h1 {
      text-align: center;
      color: var(--page-color-white);
    }
  }

  .main {
    background-color: var(--page-color-white);
    padding: 24px;
    height: calc(100vh - var(--header-height));
    overflow-y: scroll;

    .upload-field {
      padding: 12px;
      border-radius: 10px;
      border: 1px dashed black;
      height: 300px;
      overflow-y: scroll;
      &:hover {
        cursor: pointer;
        border: 1px dashed blue;
        span,
        p {
          color: blue;
        }
      }
      span.ant-upload {
        display: flex;
        height: 300px;
        overflow-y: scroll;
        width: 300px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          text-align: center;
        }
      }
    }

    .result-table-field {
      flex: 1;
      padding: 12px;
      padding-top: 0px;
    }

    .result-image-field {
      margin-top: 36px;
      .image-item {
        .ant-card-head {
          text-align: center;
          background-color: var(--page-color-green);
        }
      }
    }
  }

  footer {
  }
`;

export const DiseasePredictionWrapper = styled.div`
  .ant-upload-list-item-name {
    color: white;
  }
  .ant-upload-text-icon svg path {
    fill: white;
  }
  .upload-field {
    background: transparent;
    border: 1px dashed #ffffff;
    border-radius: 10px;
    height: 180px;

    .ant-upload-drag-container {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      height: 100%;
    }

    .upload-left {
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .upload-right {
      h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        text-align: left;
      }

      ul {
        list-style-type: circle;
        padding-left: 16px;
      }
      ul li {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #f0eaea;
      }
    }
  }

  .prediction-step-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    align-items: center;

    .footer-left {
      .upload-infor {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        margin-bottom: 0px;
      }
    }

    .cancel-btn {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff;
      border-radius: 20px;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }

  .progress-upload {
    .ant-progress-text {
      color: white;
    }
  }

  .noti-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    text-align: justify;

    /* Status Color/Gray palette 2. Reject. Inactive */

    color: #b0b0b0;
  }

  .text-green {
    color: #0a9921 !important;
  }
  .text-red {
    color: #ea0000 !important;
  }

  .result-field {
    .info-field {
      display: flex;
      justify-content: space-between;
    }

    .info-field__item {
    }

    /* .info-field__item + .info-field__item {
      margin-left: 32px;
    } */

    .info-field__item-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }

    .prediction-value {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #419b6e;
    }

    .images-field {
      display: flex;
      justify-content: center;
    }

    .footer-prediction {
      margin-top: 32px;
      display: flex;
      justify-content: end;
    }
  }

  .no-prediction {
    color: white;
    font-size: 30px;
  }

  .predict-img {
    width: 200px;
    height: 200px;
  }

  .prediction-list {
    padding-left: 0px;
    li {
      font-weight: 700;
      font-size: 20px;
      color: #419b6e;
    }
  }

  /*  */
  .upload-container {
    & > span {
      flex: 1;
    }
    .chose-from-profile {
      border: 1px dashed white;
      border-radius: 10px;
      padding: 8px;
      margin-left: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 10%; */
      max-width: 30%;

      .chose-from-profile__btn button {
        height: auto;
        white-space: break-spaces;
        margin-right: 0px !important;
      }
    }
  }
`;

export const FolderManagementWrapper = styled.div`
  display: flex;
  height: 80%;
  width: 100%;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
  }
`;

export const PatientModalWrapper = styled(Modal)`
  .ant-modal-close-x {
    padding-top: 6px;
  }

  .ant-modal-header {
    border: none;
  }

  .footer {
    margin-top: 32px;
    margin-bottom: 12px;
  }
`;

export const HealthProfileModalWrapper = styled(Modal)`
  .health-profile-container {
    max-height: calc(100vh * 0.9);
    overflow-y: scroll;
  }
`;

export const ViewEmbedPacsWrapper = styled.div`
  .btn-leave-pacs {
    padding: 0px !important;
    svg {
      width: 48px;
      height: 32px;
    }
  }

  .btn-making-prediction {
    &:hover {
      filter: brightness(1.2);
    }
  }
`;
