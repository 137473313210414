import clientUtils from "@utils/client-utils";
import { combineUrlParams } from "@utils/index";
const baseUrl = "/datahub";
const datahubServiceProvider = {
  createAsset(body) {
    let url = `${baseUrl}/asset`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "POST",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
          body,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getCollectedData() {
    let url = `${baseUrl}/asset/collected`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  toggleShowOnMarketplace(cid) {
    let url = `${baseUrl}/asset/toggle-show/${cid}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getRequest(params) {
    let url = `${baseUrl}/request`;
    url = combineUrlParams(url, params);
    return new Promise((resolve, reject) => {clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getRequestCount() {
    let url = `${baseUrl}/request/count`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  getHistory(params) {
    let url = `${baseUrl}/history`;
    url = combineUrlParams(url, params);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  toggleLikeStatus(id) {
    let url = `${baseUrl}/asset/toggle-like/${id}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getLikedData(params) {
    let url = `${baseUrl}/asset/favorite`;
    url = combineUrlParams(url, params);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  search(params) {
    let url = `${baseUrl}/asset`;
    url = combineUrlParams(url, params);
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getDataByCID(cid) {
    let url = `${baseUrl}/asset/${cid}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  getNotification() {
    let url = `${baseUrl}/notification`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  countUnreadNotification() {
    let url = `${baseUrl}/notification/count-unread`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  toggleRead(id) {
    let url = `${baseUrl}/notification/toggle-read/${id}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  deleteNotification(id) {
    let url = `${baseUrl}/notification/${id}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "DELETE",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  readAll() {
    let url = `${baseUrl}/notification/read-all`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  deleteAll() {
    let url = `${baseUrl}/notification/delete-all`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "DELETE",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  increaseView(id) {
    let url = `${baseUrl}/asset/increase-view/${id}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  getPacsData(params) {
    let url = `${baseUrl}/pacs`;
    url = combineUrlParams(url, params);

    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
};

export default datahubServiceProvider;
