export const customMessage = [
  {
    requireMessage: [
      "MultiSig Contract: Not enough votes!",
      "IvirseToken Contract: Not enough votes!",
      "TokenManagement Contract: Not enough votes!",
    ],
    customMessage:
      "Sorry, this action cannot be performed due to insufficient votes.",
  },
  {
    requireMessage: [
      "user rejected transaction",
      "User rejected the transaction",
      "User canceled",
    ],
    customMessage: "The transaction has been rejected.",
  },
  {
    requireMessage: ["MultiSig Contract: Already owner!"],
    customMessage: `Sorry, you can't remove an account has "Active" status from list.`,
  },
  {
    requireMessage: [
      "MultiSig Contract: Account existed!",
      "AdminManagement: Account existed",
      "MasterAdminManagement: not in request list"
    ],
    customMessage: `Sorry, this account has already existed!`,
  },
  {
    requireMessage: ["AdminManagement: Not admin"],
    customMessage: `This wallet address is not in admin list`,
  },
  {
    requireMessage: ["ConsensusManagement: Not enough vote"],
    customMessage: `Not enough rejecting vote to delete. Please try again once you do`,
  },

  {
    requireMessage: [
      "AdminManagement: account is a contract",
      "MasterAdminManagement: account is a contract",
    ],
    customMessage: `You've entered the address of a smart contract. Please try again using a wallet address`,
  },

  {
    requireMessage: ["AdminManagement: Last administrator"],
    customMessage: `One admin must always be active. Please propose another admin to revoke this active one.`,
  },

  {
    requireMessage: [
      "MasterAdminManagement: accepted",
      "AdminManagement: accepted",
      "VoteFactory: accepted",
      "MultiSig Contract: Accepted!",
      "TokenManagement Contract: Rejected!",
      "IvirseToken Contract: Accepted!",
    ],
    customMessage: `You've voted Approve, please refresh to see the update.`,
  },
  {
    requireMessage: ["CampaignManagement: Not default"],
    customMessage: `Please reload to see the update.`,
  },
  {
    requireMessage: [
      "AdminManagement: rejected",
      "MasterAdminManagement: rejected",
      "VoteFactory: rejected",
      "MultiSig Contract: Not accept!",
      "TokenManagement Contract: Accepted!",
      "IvirseToken Contract: Rejected!",
    ],
    customMessage: `You've voted Reject, please refresh to see the update.`,
  },
  {
    requireMessage: ["MultiSig Contract: Have more than one vote!"],
    customMessage: `Have more than one vote!`,
  },
  {
    requireMessage: ["IvirseToken Contract: mint amount exceeds max supply"],
    customMessage: `Token available to mint is not enough for this mint request.!`,
  },
];
