import { ListBreadCrumb } from "@constants/breadcrumb";
import LoadingIndicator from "@layouts/LoadingIndicator";
import PacsView from "@pages/trade/pacs-view";
// import {
//   getFirebaseToken,
//   onMessageListener,
//   requestPermissions,
// } from "@utils/firebase-utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import TradeHome from "./TradeHome";
import GlobalIcons from "./components/GlobalIcons";
import RootApp from "./root";
import { WrapperStyled } from "./styled";
import Trade from "./tradeRoute";

const App = ({ history }) => {
  const loading = useSelector((state) => state.global.loading);

  const updateBreadCrumb = useDispatch()["global"].updateData;

  useEffect(() => {
    if (ListBreadCrumb?.includes(window.location.pathname)) {
      updateBreadCrumb({
        breadcrumb: window.location.pathname,
      });
    }
  }, [window.location.pathname]);

  return (
    <WrapperStyled>
      <RootApp />
      <Switch>
        <Route path={"/pacs-view"} component={PacsView} exact />
        <Route path={"/"} component={TradeHome} exact />

        <Route path={"/"} component={Trade} />
        <Redirect to={"/"} />
      </Switch>

      {loading && <LoadingIndicator alwayDisplay />}
      {window.location.pathname === "/data-hub" ? "" : <GlobalIcons />}
    </WrapperStyled>
  );
};

export default App;
