import {
  colorMineShaft950,
  colorShamrock400,
  whiteColor,
} from "@src/styles/modules/colors";
import styled, { css } from "styled-components";

const colors = {
  light: css`
    color: ${whiteColor};
  `,
  black: css`
    color: ${colorMineShaft950};
  `,
  green: css`
    color: ${colorShamrock400};
  `,
};

export const CellTextStyled = styled.span`
  font-size: 14px;
  ${(props) => colors[props.color] || colors.light}
  ${(props) => `font-weight: ${props.weight}`};
  &.cell-icon {
    display: flex;
    align-items: center;
  }
  overflow-wrap: break-word;
`;
