import adminProvider from "@data-access/admin-provider";
import useJavaServiceInstance from "../useJavaServiceModel";
const model = "admin";
const provider = adminProvider;
const admin = useJavaServiceInstance({
  model,
  provider,
  initState: { required: 1 },
  additionEffect: ({ dispatch }) => ({
    sync: async ({ callback }, state) => {
      const consensusService = state.consensus.consensusService;
      const { name: network } = state.contracts.currentContractProperties;
      try {
        let response = await adminProvider.getMultiple({
          page: 0,
          size: 999999,
          network,
        });
        let allData = response.data;
        let listRequest = await consensusService.getAllAdmin();
        let addressInRequestList = allData.filter((item) =>
          listRequest.includes(item.walletAddress)
        );
        let addressNotInRequestList = allData.filter(
          (item) => !listRequest.includes(item.walletAddress)
        );
        for (let index = 0; index < addressNotInRequestList.length; index++) {
          const element = addressNotInRequestList[index];
          if (element.status == 10) {
            let res = await adminProvider.delete(element.id);
          }
        }

        for (let index = 0; index < addressInRequestList.length; index++) {
          const element = addressInRequestList[index];
          let status = element.status;
          let blockchainStatus = await consensusService.getAdminStatus(
            element.walletAddress
          );
          if (status == 0 && blockchainStatus == 0) {
            let res = await adminProvider.patch(element.id, { status: 10 });
          }

          if (status == 10 && blockchainStatus == 1) {
            let res = await adminProvider.patch(element.id, { status: 30 });
          }

          if (status == 20 && blockchainStatus == 2) {
            let res = await adminProvider.patch(element.id, { status: 40 });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        callback();
      }
    },
  }),
});
export default admin;
