import LocalStorageProvider from "@data-access/local-provider";
import clientUtils from "@utils/client-utils";
import { strings } from "@utils/index";
import snackbarUtils from "@utils/snackbar-utils";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWalletClient } from "wagmi";

function RootApp() {
  const history = useHistory();
  const { connect, getPublicKey, setUpOwner } = useDispatch().contracts;
  const { generateToken, setAuthenticationData } = useDispatch().auth;

  const generatePublicKey = (walletClient) => {
    setUpOwner(walletClient).then(() => {
      snackbarUtils.confirm({
        title: strings("message.signToGetPublicKey"),
        okText: strings("message.sign"),
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {
          getPublicKey()
            ?.then((res) => {
              window?.ReactNativeWebView?.postMessage(JSON.stringify(res));
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    });
  };

  const signMessageToLogin = debounce((walletClient) => {
    connect(walletClient).then(async (res) => {
      let address = res.address;
      let authLocalStorage = await LocalStorageProvider.read(`auth-${address}`);
      if (
        authLocalStorage?.expiration &&
        moment() < moment(authLocalStorage?.expiration) &&
        !window.fetchErrorFlag
      ) {
        clientUtils.auth = `Bearer ${authLocalStorage.access_token}`;

        setAuthenticationData({ authData: authLocalStorage });
        if (window.location.pathname === "/") {
          history.push("/tutorial");
        }
      } else {
        snackbarUtils.confirm({
          title: strings("message.signToConnect"),
          okText: strings("message.sign"),
          cancelButtonProps: { style: { display: "none" } },
          onOk: () => {
            generateToken({ history }).then((res) => {
              window.fetchErrorFlag = false;
            });
          },
        });
      }
    });
  }, 500);
  const triggerWalletClient = (walletClient) => {
    if (walletClient) {
      if (history.location.search == "?type=genKey") {
        generatePublicKey(walletClient);
      } else {
        signMessageToLogin(walletClient);
      }
    }
  };
  const { refetch } = useWalletClient({ onSuccess: triggerWalletClient });

  useEffect(() => {
    window.resign = refetch;
  }, []);

  return <></>;
}

RootApp.propTypes = {};

export default RootApp;
