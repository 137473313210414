import clientUtils from "@utils/client-utils";
const baseUrl = "/community";
const communityServiceProvider = {
  saveDraftCampaign(data) {
    let url = `${baseUrl}/campaign/save-draft`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "POST",
          url,
          isUseServiceUrl: true,
          body: data,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  updateDraftCampaign(data) {
    let url = `${baseUrl}/campaign/update-draft`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          body: data,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  requestCampaign(data) {
    let url = `${baseUrl}/campaign/request-campaign`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "POST",
          url,
          isUseServiceUrl: true,
          body: data,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  updateRequestCampaign(data) {
    let url = `${baseUrl}/campaign/update-request-campaign`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "PUT",
          url,
          isUseServiceUrl: true,
          body: data,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  deleteRequestCampaign(id) {
    let url = `${baseUrl}/campaign/${id}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "DELETE",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  createRemain(data) {
    let url = `${baseUrl}/remain`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "POST",
          url,
          isUseServiceUrl: true,
          body: data,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  getBalances() {
    let url = `${baseUrl}/explorer/get-balances`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  getNormalTransaction() {
    let url = `${baseUrl}/explorer/get-normal-transaction`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },

  getTokenTransaction() {
    let url = `${baseUrl}/explorer/get-token-transaction`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          ignoreAuth: false,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
};

export default communityServiceProvider;
