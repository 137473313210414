import TradeButton from "@components/TradeButton";
import ChangeLanguage from "@layouts/trade/header/components/ChangeLanguage";
import { VideoIcon, WalletIcon } from "@svg";
import { getBackGroundFromScreen } from "@utils/";
import { strings } from "@utils/index";
import snackbarUtils from "@utils/snackbar-utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import LinearText from "../components/LinearText";
import VideoModal from "./components/VideoModal";
import { ChangeLanguageBoxStyled, HomeStyled } from "./styled";
import { Stack } from "@mui/material";

const TradeHome = ({ history }) => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const generateToken = useDispatch().auth.generateToken;

  const videoRef = useRef();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const signer = useSelector((state) => state.contracts.signer);
  const { disconnect } = useDisconnect();
  const [scrWidth, setScrWidth] = useState(
    getBackGroundFromScreen(window.innerWidth)
  );
  useEffect(() => {
    const resizeEvent = window.addEventListener("resize", () => {
      setScrWidth(getBackGroundFromScreen(window.innerWidth));
    });
    const resizeId = window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resizeEvent);
      window.removeEventListener("resize", resizeId);
    };
  }, []);

  const handleShowVideoModal = () => {
    videoRef?.current?.show();
  };

  const handleConnect = () => {
    if (signer) {
      snackbarUtils.confirm({
        title: strings("message.signToConnect"),
        okText: strings("message.sign"),
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {
          generateToken({ history });
        },
      });
    } else {
      disconnect();
      localStorage?.clear();
      setTimeout(() => {
        open();
      }, 100);
    }
  };
  return (
    <>
      <HomeStyled id="trade-home-page">
        <div className="home-page__background">
          <img
            alt="home_bg"
            src={require(`@images/trade/home_page_bg_${scrWidth}.png`)}
          />
        </div>
        <div className="home-page__header d-flex justify-content-space-between align-items-center">
          <Link to="/">
            <div className="logo-home d-flex align-items-center">
              <img alt="logo-ivi" src={require("@images/trade/logo-ivi.png")} />
            </div>
          </Link>
          <Stack direction="row" alignItems="center" spacing={1.2}>
            {" "}
            <Button
              className="connect-wallet-btn d-flex align-items-center"
              onClick={handleConnect}
            >
              <WalletIcon />
              <span>Connect Wallet</span>
            </Button>{" "}
            <ChangeLanguageBoxStyled>
              <ChangeLanguage />
            </ChangeLanguageBoxStyled>
          </Stack>
        </div>
        <div className="home-page__body">
          <div className="home-page__body-content">
            <div className="big-text">
              {/* <h1>IVIRSE</h1>
              <h1>DigiHealth Ecosystem</h1> */}
              {/* <img
                src={require("@images/trade/home-page-big-text.png")}
                alt="big text"
              /> */}
              <LinearText
                title={"IVIRSE"}
                className="big-text-up"
                fontSize={screenWidth > 576 ? "80px" : "50px"}
                lineHeight={screenWidth > 576 ? "82px" : "52px"}
                style={{ marginBottom: "0px" }}
              />
              <LinearText
                title={"DigiHealth Ecosystem"}
                className="big-text-down"
                fontSize={screenWidth > 576 ? "40px" : "30px"}
                lineHeight="45px"
              />
            </div>
            <p className="small-text">
              IVIRSE is creating a digihealthcare universal that provides an
              innovative perspective for individuals via evolving digital health
              ecosystems.
            </p>
            <div className="button-group d-flex">
              <TradeButton
                icon={<VideoIcon />}
                content={"Watch video"}
                type="transparent_white"
                onClick={handleShowVideoModal}
              />
            </div>
          </div>
        </div>
        <div
          className={
            screenWidth > 576
              ? "home-page__footer d-flex justify-content-end"
              : "home-page__footer"
          }
        >
          <div className="home-page__footer-right">
            <LinearText
              title={
                <span>
                  Better Healthcare
                  <br />
                  from Digital Revolution
                </span>
              }
              fontSize="20px"
              lineHeight="24px"
            />
          </div>
        </div>
      </HomeStyled>
      <VideoModal ref={videoRef} />
    </>
  );
};

export default TradeHome;