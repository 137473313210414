import feeProvider from "@data-access/fee-provider";
import useJavaServiceInstance from "../useJavaServiceModel";
const model = "fee";
const provider = feeProvider;
const campaign = useJavaServiceInstance({
  model,
  provider,
  additionEffect: ({ dispatch }) => ({
    sync: async ({ callback }, state) => {
      const consensusService = state.consensus.consensusService;
      const { name: network } = state.contracts.currentContractProperties;
      try {
        let response = await provider.getMultiple({
          page: 0,
          size: 999999,
          network,
        });
        let allData = response.data;
        let listRequest = await consensusService.getAllVoteFeeRequest();
        for (let index = 0; index < allData.length; index++) {
          const element = allData[index];
          if (
            element.blockchainId != null &&
            element.blockchainId < listRequest.length
          ) {
            let status = element.status;
            let blockchainRecord = listRequest[element.blockchainId];
            let blockchainStatus = blockchainRecord.status;

            if (status == 0 && blockchainStatus == 0) {
              let res = await provider.patch(element.id, {
                status: 20,
              });
            }

            if (status == 20 && blockchainStatus == 1) {
              let res = await provider.patch(element.id, {
                status: 30,
              });
            }

            if (status == 20 && blockchainStatus == 2) {
              let res = await provider.patch(element.id, {
                status: 40,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        callback();
      }
    },
  }),
});
export default campaign;
