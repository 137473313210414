import React from "react";
import ImageThumbnail from "./ImageThumbnail";
import useCustomState from "@hook/useCustomState";

// const studies = [{}, {}, {}];

function GroupDate({ study, setRender }) {
  const [state, setState] = useCustomState({ collapse: true, init: false });

  return (
    <div class="card">
      <div
        class="card-header"
        id="heading{{study.studyIndex}}"
        onClick={() => {
          setState({ collapse: !state.collapse, init: true });
        }}
      >
        <div
          data-toggle="collapse"
          data-target="#collapse{{study.studyIndex}}"
          aria-expanded="true"
          aria-controls="collapse{{study.studyIndex}}"
        >
          <div class="study-item-box">
            <div class="study-modality">
              <div class="study-modality-text">
                {study.modalitiesList || study.modality || "CT"}
              </div>
            </div>
            <div class="study-text">
              <div class="study-date">{study.studyDate || "Invalid Date"}</div>
              <div class="study-description">
                {study.studyDescription || ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="collapse{{study.studyIndex}}"
        class="collapse show"
        aria-labelledby="heading{{study.studyIndex}}"
      >
        <div
          class="card-body"
          style={{ overflow: "hidden", height: state.collapse ? 0 : "auto" }}
        >
          {state.init &&
            study.seriesList?.map((item) => (
              <ImageThumbnail
                stack={item}
                setRender={setRender}
              ></ImageThumbnail>
            ))}

          {/* {{#if @first}}
                  {{#each (studyThumbnails study) as |thumbnail|}}
                      {{> ImageThumbnail isActiveStudy=true}}
                  {{/each}}
              {{else}}
                  {{#each (studyThumbnails study) as |thumbnail|}}
                      {{> ImageThumbnail}}
                  {{/each}}
              {{/if}} */}
        </div>
      </div>
    </div>
  );
}

function StudyBrowser({ data = [], setRender = () => {} }) {
  const studies = data;
  return (
    <div id="studyBrowser" class="studyBrowser" style={{ overflowY: "scroll" }}>
      <div class="scrollableStudyThumbnails">
        {studies.length === 1 ? (
          studies.map((item) => <ImageThumbnail></ImageThumbnail>)
        ) : (
          <div class="accordion" id="studyBrowserAccordion">
            {studies.map((study) => (
              <GroupDate study={study} setRender={setRender}></GroupDate>
            ))}

            {/* {{/each}} */}
          </div>
        )}
        {/* { */}
        {/* {{#if (eq studies.length 1)}}
            {{#each studies as |study|}}
                {{#each (studyThumbnails study) as |thumbnail|}}
                    {{> ImageThumbnail isActiveStudy=true}}
                {{/each}}
            {{/each}}
        {{else}} */}

        {/* {{/if}} */}
      </div>
    </div>
  );
}

export default StudyBrowser;
