import React, { useEffect, useRef } from "react";
import ImageControls from "./ImageControls";
import Toolbar from "./Toolbar";
import ViewportOrientationMarkers from "./ViewportOrientationMarkers";

import Hammer from "hammerjs";
import dicomParser from "dicom-parser";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/cornerstoneWADOImageLoader.js";
import * as cornerstoneTools from "cornerstone-tools";
import StudyBrowser from "./StudyBrowser";
import useCustomState from "@hook/useCustomState";
import { toolManager } from "./lib/viewerMain/tools/toolManager";
import { viewportUtils } from "./lib/viewerbase/viewportUtils";
import { convertUrlPacs } from "@utils/index";
// import { tools } from "./lib/viewerMain/tools";
// Externals
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

// // CodeSandbox live updates components in an odd way.
// // We do this to protect ourselves from duplicate initializations
if (!cornerstoneWADOImageLoader.initialized) {
  // WadoImageLoader Registration/Config
  const config = {
    webWorkerPath: "/codecs/cornerstoneWADOImageLoaderWebWorker.js",
    taskConfiguration: {
      decodeTask: {
        codecsPath: "/codecs/cornerstoneWADOImageLoaderCodecs.js",
      },
    },
  };
  cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
  cornerstoneWADOImageLoader.initialized = true;
}

function ViewerMain({ dataStudy }) {
  const [state, setState] = useCustomState({ init: false });
  const canvas2 = useRef();
  const setRender = ({ data }) => {
    const synchronizer = new cornerstoneTools.Synchronizer(
      "cornerstonenewimage",
      cornerstoneTools.updateImageSynchronizer
    );
    const canvasStack2 = {
      currentImageIdIndex: 0,
      imageIds: data.imageIds.map((x) => convertUrlPacs(x)),
    };
    cornerstone.enable(canvas2.current);
    cornerstoneTools.mouseWheelInput.enable(canvas2.current);
    cornerstoneTools.mouseInput.enable(canvas2.current);
    cornerstone.loadImage(canvasStack2.imageIds[0]).then((image) => {
      cornerstone.displayImage(canvas2.current, image);
      viewportUtils.resetViewport();
      cornerstoneTools.addStackStateManager(canvas2.current, ["stack"]);
      cornerstoneTools.addToolState(canvas2.current, "stack", canvasStack2);
      const element = canvas2.current;
      cornerstoneTools.mouseInput.enable(canvas2.current);
      // cornerstoneTools.touchInput.enable(canvas2.current);
      cornerstoneTools.mouseWheelInput.enable(canvas2.current);
      // cornerstoneTools.wwwc.activate(element, 1); // left click
      cornerstoneTools.pan.activate(element, 1); // middle click
      // cornerstoneTools.zoom.activate(element, 2);
      cornerstoneTools.stackScrollWheel.activate(canvas2.current);
      // cornerstoneTools.wwwcTouchDrag.activate(element); // - Drag
      // cornerstoneTools.zoomTouchPinch.activate(element); // - Pinch
      // cornerstoneTools.panMultiTouch.activate(element);
      // set the stack as tool state
      // console.log(cornerstoneTools, "canvas2.current.children[0]");
      // synchronizer.add(canvas2.current);
      // cornerstoneTools.stackScroll.activate(canvas2.current, 2);
      // cornerstoneTools.wwwc.activate(canvas2.current, 1);
      // cornerstoneTools.stackScroll.enable();
    });
  };
  useEffect(() => {
    // ----------------- bỏ đọc ảnh dicom trên wb đi
    // todo
    global.cornerstoneTools = cornerstoneTools;
    cornerstone.enable(canvas2.current, {
      renderer: "webgl",
    });
  }, []);
  useEffect(() => {
    // toolManager.configureTools();
    // cornerstone.enable(canvas2.current);
    // cornerstoneTools.stackScroll.enable();
    // cornerstoneTools.init({
    //   globalToolSyncEnabled: true,
    // });
    // Grab Tool Classes
    // global.cornerstoneTools = cornerstoneTools;
    // const StackScrollMouseWheelTool =
    //   cornerstoneTools.StackScrollMouseWheelTool;
    // const commandsManager = tools.registerTools();
    // tools.createToolbar(commandsManager);
    // Add them
    // cornerstoneTools.addToolState(cornerstoneTools.stackScrollWheel);
    // cornerstoneTools.addTool(ZoomTool);
    // cornerstoneTools.addTool(WwwcTool);
    // cornerstoneTools.addTool(PanMultiTouchTool);
    // cornerstoneTools.addTool(ZoomTouchPinchTool);
    // cornerstoneTools.addTool(StackScrollMouseWheelTool);
    // cornerstoneTools.StackScrollWheel.activate(canvas2);
    // Set tool modes
    // cornerstoneTools.setToolActive("Pan", { mouseButtonMask: 4 }); // Middle
    // cornerstoneTools.setToolActive("Zoom", { mouseButtonMask: 2 }); // Right
    // cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: 1 }); // Left & Touch
    // cornerstoneTools.setToolActive("PanMultiTouch", {});
    // cornerstoneTools.setToolActive("ZoomMouseWheel", {});
    // cornerstoneTools.setToolActive("ZoomTouchPinch", {});
    // cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
    // console.log(cornerstoneTools.StackScrollMouseWheelTool);
    // cornerstone.enable(canvas1.current, {
    //   renderer: "webgl",
    // });
    // canvas2.current.style.width = 200;
    // canvas2.current.width = 200;
    // cornerstone.enable(canvas2.current, {
    //   renderer: "webgl",
    // });
    // const codeSandboxProjectUrl = "https://9jy10r8x7w.codesandbox.io";
    // Setup canvas 1
    // const canvasStack1 = {
    //   currentImageIdIndex: 0,
    //   imageIds: [
    //     "wadouri:http://pacsapp.mediplus.vn:741/vrpacs-scu/study-get-public?link=2MypsrMTGaOHVYGXJ2LH8nK+wWGM4IqbxgtoXXCI3_qR3v_p8QkMsPVa5PNWEbjlEcyhCeeR7urZdwkhDd+UpYHU7+nxAxCs81zw+1sBu+kbz6AE+5L89sssQmBKmNb3kd7_6OwBEKzzV_37Whu_5RvJpBy1oQ.mRlAJjkYSo5ZJGRO5K7_VQ&file=0005.1.2.840.113619.2.44.10795885.11628122.89718.1659918885.155.dcm",
    //     `wadouri:http://localhost:3000/image11.dcm`,
    //     // `wadouri:/vrpacs-scu/study-get-public?link=jI2+rDiwQJfPjsMi9TmCcFbkpq9Q6RMffSwgwGbgyM3Fn+j3eqpVhL2BpkaESv1nNZbGxzuYd25iUEG8G7eDktWV+Pd6oEmYu4eyTola_ms_lcfKJ5tlcnALCv1c8MHAxZ_o9meiSZi7jL9OiUD6Zz+Wx9JpqA.982YISP37Z1m_5c6tj11EA&file=thum.jpg`,
    //     `wadouri:${codeSandboxProjectUrl}/s1/2.dcm`,
    //   ],
    // };
    // cornerstone.loadImage(canvasStack1.imageIds[0]).then((image) => {
    //   cornerstone.displayImage(canvas1.current, image);
    //   // set the stack as tool state
    //   synchronizer.add(canvas1.current);
    //   cornerstoneTools.addStackStateManager(canvas1.current, [
    //     "stack",
    //     "crosshairs",
    //   ]);
    //   cornerstoneTools.addToolState(canvas1.current, "stack", canvasStack1);
    // });
    // DCMViewer.ui.renderViewer();
    // DCMViewer.tools.toolManager.getActiveTool();
    // setTimeout(() => {
    //   const CrosshairsTool = cornerstoneTools.CrosshairsTool;
    //   console.log(synchronizer);
    //   console.log(synchronizer.getSourceElements());
    //   cornerstoneTools.addTool(CrosshairsTool);
    //   cornerstoneTools.setToolActive("Crosshairs", {
    //     mouseButtonMask: 1,
    //     synchronizationContext: synchronizer
    //   });
    // }, 5000);
  }, []);
  return (
    <div id="viewerMain">
      <Toolbar></Toolbar>
      <div class="content">
        <div class="sidebarMenu {{#if seriesPanelOpen}} sidebar-open {{/if}}">
          <StudyBrowser data={dataStudy} setRender={setRender}></StudyBrowser>
        </div>
        <div class="mainContent {{#unless seriesPanelOpen}}content-full{{/unless}}">
          <div id="layoutManagerTarget">
            <div class="viewportContainer">
              <div class="removable">
                {/* <img
                  src={state.url}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                  }}
                /> */}
                <div
                  ref={canvas2}
                  className="imageViewerViewport"
                  id="imageViewerViewport"
                  unselectable="on"
                  tabindex="0"
                ></div>
                {/* <ImageControls /> */}
                {/* <ViewportOrientationMarkers /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="loadingViewerMain" style={{ zIndex: 2, background: "rgba(0,0,0,0.5)" }}>
        10 <span id="loadingPercentage">0</span>%
      </div> */}
    </div>
  );
}

export default ViewerMain;
