import clientUtils from "@utils/client-utils";
import baseProvider from "./base-provider";
const baseUrl = "/request";
const requestProvider = {
  ...baseProvider(baseUrl),
  login({ network, address }) {
    let url = `${baseUrl}/login`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          ignoreAuth: false,
          methodType: "POST",
          url,
          isUseServiceUrl: true,
          body: { network, address },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getDetailRecord({ network, address }) {
    let url = `${baseUrl}/detail?walletAddress=${address}&network=${network}`;
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi({
          ignoreAuth: false,
          methodType: "GET",
          url,
          isUseServiceUrl: true,
          body: { network, address },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
};

export default requestProvider;
