import { device } from "@src/styles/modules/media-query";
import { zIndex } from "@src/styles/modules/z-index";
import { css } from "styled-components";
import {
  colorDoveGray500,
  colorSilver300,
  colorWildSand50,
  whiteColor
} from "./modules/colors";

export default css`
  .modal-footer-border-none {
    .ant-modal-footer {
      border: none !important;
    }
  }

  .modal-custom-close-icon {
    .ant-modal-close-x {
      width: 54px !important;
      height: 54px !important;
      line-height: 54px !important;
      .ant-modal-close-icon {
        svg {
          color: ${colorDoveGray500} !important;
          width: auto !important;
          height: auto !important;
          transition: 0.2s;
          &:hover {
            color: ${colorSilver300} !important;
          }
        }
      }
    }
  }

  .modal-expand-button-actions {
    .ant-modal-footer {
      .ant-btn {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  .ant-popover {
    &.show-arrow {
      .ant-popover-arrow {
        display: block !important;
      }
    }
    &.staking-popover {
      max-width: 400px;
      z-index: ${zIndex.popover};
      .ant-popover-inner-content {
        padding: 2px 8px;
      }
      .ant-popover-inner {
        background-color: transparent;
      }
      ${device.maxMobile} {
        max-width: 100%;
      }
    }
    &.titan {
      .ant-popover-inner-content {
        background-color: #f2efff;
      }
    }
    &.black {
      .ant-popover-inner-content {
        background-color: rgb(28, 12, 49, 0.9);
        color: ${whiteColor};
        border-radius: 8px;
      }
      .ant-popover-arrow-content:before {
        background-color: rgb(28, 12, 49, 0.9);
      }
    }
  }

  .staking-cu-proposal-field {
    &.ant-select-dropdown {
      border-radius: 0px !important;
    }
  }

  .rc-virtual-list-scrollbar-thumb {
    background: ${colorSilver300} !important;
  }

  .spin-wrapper-transparent {
    .ant-spin-container:after {
      background-color: transparent !important;
    }
  }

  .ant-menu-sub {
    &-bg-grey {
      .ant-menu-sub {
        background: ${colorWildSand50} !important;
      }
    }

    &-bg-dark {
      .ant-menu-sub {
        background: rgba(36, 13, 64, 1) !important;
      }
    }
  }

  .ant-menu .ant-menu-item a::before {
    inset: inherit;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    outline: none !important;
    background-color: transparent !important;
  }
`;
