import { ArrowUpGreen } from "@assets/svg";
import styled from "styled-components";

const DropdownIconStyled = styled(ArrowUpGreen)`
  margin-left: 13px;
  display: inline-block;
  padding: 4px;
  box-sizing: content-box;
  path {
    stroke: ${(props) => props.color};
  }
`;

export default function DropDownIcon(props) {
  return <DropdownIconStyled {...props} />;
}
