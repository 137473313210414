import { NavLink, hoverActiveStyle } from "@layouts/trade/header/styled";
import { BaiJamjuree } from "@src/styles/modules/fonts";
import { device, size } from "@src/styles/modules/media-query";
import { Menu } from "antd";
import styled from "styled-components";

const { SubMenu } = Menu;

export const NavSubMenuText = styled.span`
  color: ${(props) => props.color} !important;
  font-size: 16px;
  font-weight: 400;
  font-family: ${BaiJamjuree};
  transition: 0.2s;
  display: flex;
  align-items: center;
`;

export const NavMenu = styled(Menu)`
  ${device.maxDesktop} {
    display: none;
  }
  && {
    .ant-menu-sub {
      border-radius: 5px;
    }
  }

  flex-grow: 1;

  &.ant-menu {
    background-color: transparent;
    border: none;
    justify-content: space-between;
    ${device.maxLargeDesktop} {
      margin-right: 40px;
    }
  }
  .ant-menu-overflow-item {
    padding: 0px 20px !important;
    ${device.maxLargeDesktop} and (min-width: ${size.lg}px) {
      padding: 0px !important;
    }
    outline: none !important;
    cursor: inherit !important;
    &.active {
      .ant-menu-link {
        ${hoverActiveStyle}
      }
    }
  }
`;

export const NavSubMenu = styled(SubMenu)`
  &.ant-menu-submenu {
    ${NavLink}
    i {
      display: none;
    }
    .icon-toggle-dropdown {
      transition: 0.3s;
      transform: rotateZ(0deg);
      opacity: 0.8;
    }
    &-open {
      > div > span > span .icon-toggle-dropdown {
        transform: rotateZ(180deg);
        opacity: 1;
      }
    }
    &-active,
    &-selected,
    &-open,
    &.active {
      > div {
        svg {
          transition: 0.3s;
          path {
            stroke: ${(props) => props.color};
          }
        }
        i {
          color: ${(props) => props.color} !important;
        }
        > span.ant-menu-title-content > span {
          color: ${(props) => props.color} !important;
          font-weight: 600;
          background-color: transparent !important;
        }
      }
    }
  }
`;

export const NavItem = styled(Menu.Item)`
  &.ant-menu-item {
    ${NavLink}
    &.active,
    &-active,
    &-selected {
      background-color: transparent !important;
      .ant-menu-link,
      .ant-menu-title-content {
        ${hoverActiveStyle}
      }
    }
    &-right {
      margin-left: auto;
      padding-right: 0px !important;
      height: auto !important;

      ${device.maxTablet} {
        overflow: visible !important;
        margin-top: 30px !important;
        text-align: center;
        display: block !important;
      }
    }
  }
`;

export const MenuHorizontalComponents = {
  NavItem,
  NavMenu,
  NavSubMenu,
  NavSubMenuText,
};
