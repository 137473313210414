import requestProvider from "@data-access/request-provider";
import useJavaServiceInstance from "../useJavaServiceModel";
const model = "request";
const provider = requestProvider;
const request = useJavaServiceInstance({
  model,
  provider,
  additionEffect: ({ dispatch }) => ({
    getDetailRecord: ({ address, network }) => {
      return new Promise((resolve, reject) => {
        provider
          .getDetailRecord({ address, network })
          .then((res) => {
            if (res.code === 0) {
              dispatch[model].updateData({ detailMasterAdmin: res?.data });
            } else {
              dispatch[model].updateData({ detailMasterAdmin: {} });
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getRequestRecord: ({ address, network }) => {
      return new Promise((resolve, reject) => {
        provider
          .getDetailRecord({ address, network })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sync: async ({ callback }, state) => {
      const consensusService = state.consensus.consensusService;
      const { name: network } = state.contracts.currentContractProperties;
      try {
        let response = await requestProvider.getMultiple({
          page: 0,
          size: 999999,
          network,
        });
        let allData = response.data;
        let listRequest = await consensusService.getAllListRequestMasterAdmin();
        let addressInRequestList = allData.filter((item) =>
          listRequest.includes(item.walletAddress)
        );
        let addressNotInRequestList = allData.filter(
          (item) => !listRequest.includes(item.walletAddress)
        );
        for (let index = 0; index < addressNotInRequestList.length; index++) {
          const element = addressNotInRequestList[index];
          if (element.status == 10) {
            let res = await requestProvider.delete(element.id);
          }
        }
        for (let index = 0; index < addressInRequestList.length; index++) {
          const element = addressInRequestList[index];
          let status = element.status;
          let blockchainStatus = await consensusService.getMasterAdminStatus(
            element.walletAddress
          );
          let adminStatus = await consensusService.getAdminStatus(
            element.walletAddress
          );
          if (status == 0 && blockchainStatus == 0) {
            let res = await requestProvider.patch(element.id, { status: 10 });
          }

          if (status == 10 && blockchainStatus == 1) {
            let res = await requestProvider.patch(element.id, { status: 30 });
          }

          if ([20, 30].includes(status) && blockchainStatus == 2) {
            let res = await requestProvider.patch(element.id, { status: 40 });
          }
          if (adminStatus == 2 && status != 40) {
            let res = await requestProvider.patch(element.id, { status: 40 });
          }
        }
        console.log(allData, listRequest);
      } catch (error) {
        console.log(error);
      } finally {
        callback();
      }
    },
  }),
});
export default request;
