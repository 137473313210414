import { css } from "styled-components";
import { colorSilver300 } from "./modules/colors";

export const customScrollbar = (width, color = colorSilver300) => css`
  ::-webkit-scrollbar {
    width: ${width}px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 ${width}px ${width}px transparent;
    border: solid ${width - 10}px transparent;
    border-radius: ${width}px;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 ${width}px ${width}px ${color};
    border: solid ${width - 10}px transparent;
    border-radius: ${width}px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const customTextLines = (line) => css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
`;
