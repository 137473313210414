import { CustomTypography } from "@components/Elements/Typography";
import communityServiceProvider from "@data-access/community-service-provider";
import contractProvider from "@data-access/contract-provider";
import { IvirseIntegrationOwner } from "@ivirse-tovchain/integration-services/lib/integration-client";
import { getState } from "@redux";
import { colorDoveGray500 } from "@src/styles/modules/colors";
import { applyDecimals } from "@utils";
import { toDecimal } from "@utils/index";
import snackbarUtils from "@utils/snackbar-utils";
import { disconnect as disconnectWeb3 } from "@wagmi/core";
import { ethers } from "ethers";
import moment from "moment";
import { CURRENT_CONFIG } from "../../../config";
import userServiceProvider from "../../../data-access/user-service-provider";
import { ownerStorageConfig, ROOT_PEM } from "../datasharing/config";
import IVIRSE_TOKEN_ABI from "./abi.json";
import { ERC20ABI as LiteERC20ABI } from "./constants";

const contracts = {
  state: {},
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    connect: async (walletClient) => {
      try {
        const web3Provider = new ethers.providers.Web3Provider(
          walletClient,
          "any"
        );
        const network = await web3Provider.getNetwork();
        const signer = web3Provider.getSigner();
        const address = await signer.getAddress();
        let result = {};

        const currentContractProperties = CURRENT_CONFIG;
        const contract = new ethers.Contract(
          currentContractProperties.address,
          IVIRSE_TOKEN_ABI,
          web3Provider
        );

        const erc20 = contract.connect(signer);

        result = {
          web3Provider,
          address,
          erc20,
          currentContractProperties,
          signer,
          contract,
          chainId: network.chainId,
        };

        dispatch.contracts.updateData(result);
        dispatch.datasharing.setupConnectOwner();
        dispatch.datasharing.setupConnectBuyer();
        return result;
      } catch (error) {
        throw error;
      }
    },
    setUpOwner: (walletClient) => {
      return new Promise(async (resolve, reject) => {
        try {
          const web3Provider = new ethers.providers.Web3Provider(walletClient);
          const network = await web3Provider.getNetwork();
          const currentNetworkName = network?.name.toLowerCase();
          const signer = web3Provider.getSigner();
          const address = await signer.getAddress();

          const currentContractProperties = networkHasCoin.find(
            (item) => item.name.toLowerCase() === currentNetworkName
          );
          const {
            reactiveContractAddress,
            marketplaceContractAddress,
            intergrationServer,
          } = currentContractProperties;

          let owner = await IvirseIntegrationOwner.init(
            signer,
            [ROOT_PEM],
            ownerStorageConfig,
            reactiveContractAddress,
            marketplaceContractAddress,
            "",
            intergrationServer
          );

          dispatch.contracts.updateData({ signer, owner, address });
          resolve(owner);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPublicKey: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const owner = getState()?.contracts?.owner;
          const address = getState()?.contracts?.address;

          let [_, publicKeyToEncrypt] =
            await owner.genKeyForDistributedDataEncryption();
          resolve({ address, publicKeyToEncrypt });
        } catch (err) {
          reject(err);
        }
      });
    },
    disconnect: async () => {
      const callback = () => {
        dispatch.contracts.disconnect();
      };
      try {
        await Promise.all([disconnectWeb3(), userServiceProvider.logout()]);
      } catch (error) {
        return snackbarUtils.error(
          "Failed!",
          <CustomTypography my={2} color={colorDoveGray500} fontWeight={500}>
            Error while disconnecting wallet.
          </CustomTypography>,
          callback,
          "Try again",
          true
        );
      } finally {
        localStorage.clear();
        window.location.href = "/";
      }
    },
    getBalances: (_, state) => {
      return new Promise((resolve, reject) => {
        let { currentContractProperties, address, web3Provider } =
          state.contracts;

        communityServiceProvider
          .getBalances()
          .then(async (res) => {
            let fullData = res?.data || [];
            let contractAddresses = fullData.map(
              (item2) => item2?.contractAddress
            );
            let uniqueAddresses = [...new Set(contractAddresses)];
            let data = [];

            for (let index = 0; index < uniqueAddresses.length; index++) {
              const contractAddress = uniqueAddresses[index];

              let ERC20Contract = new ethers.Contract(
                contractAddress,
                LiteERC20ABI,
                web3Provider
              );
              const symbol = await ERC20Contract.symbol();
              const name = await ERC20Contract.name();
              const balance = await ERC20Contract.balanceOf(address);

              data.push({
                contractAddress,
                symbol,
                name,
                balance: Number(toDecimal(balance)),
              });
            }

            let coinBalance = await web3Provider.getBalance(address);
            data.push({
              contractAddress: ethers.constants.AddressZero,
              symbol: currentContractProperties.symbol,
              name: currentContractProperties.nameCoin,
              balance: Number(toDecimal(coinBalance)),
            });
            data.reverse();
            data = data.map((item, index) => ({ ...item, id: index }));

            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTransfers: async (type, state) => {
      return new Promise((resolve, reject) => {
        let { currentContractProperties, address, web3Provider } =
          state.contracts;
        let action =
          type != 1
            ? communityServiceProvider.getNormalTransaction
            : communityServiceProvider.getTokenTransaction;
        action()
          .then((json) => {
            let results = json?.data || [];
            let transfers = results
              .map((item) => ({
                ...item,
                time: moment
                  .unix(item?.timeStamp)
                  .format("DD-MM-YYYY HH:mm:ss"),
                coinValue: `${
                  item?.value ? item.value / Math.pow(10, 18) : 0
                } ${currentContractProperties.symbol}`,
                tokenValue: item.value / Math.pow(10, 18),
                gasFee: (item.gasPrice * item.gasUsed) / Math.pow(10, 18),
                state:
                  item.from.toLowerCase() === address.toLowerCase() ? 1 : 2,
                status: item.blockNumber ? 1 : 2,
              }))
              .reverse();

            // dispatch.contracts.updateData({
            //   transfers,
            // });
            resolve(transfers);
          })
          .catch((err) => {
            dispatch.contracts.updateData({
              transfers: [],
            });
            reject(err);
          });
      });
    },

    mint: ({ address, amount }) => {
      let erc20 = getState()?.contracts?.erc20;

      const amountToSend = applyDecimals(amount, 18, "positive");

      return new Promise((resolve, reject) => {
        erc20
          .mint(address, amountToSend)
          .then((res) => {
            resolve(res.wait());
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    transfer: ({ address, amount }) => {
      let erc20 = getState()?.contracts?.erc20;
      const amountToSend = applyDecimals(amount, 18, "positive");
      return new Promise((resolve, reject) => {
        erc20
          .transfer(address, amountToSend)
          .then((res) => {
            resolve(res.wait());
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTokenBalance: async (_, state) => {
      const { erc20, address } = state.contracts;
      if (erc20) {
        const balance = await erc20.balanceOf(address);
        dispatch.contracts.updateData({
          balance: balance?.hexToDecimal(),
        });
      }
    },

    getDataFromBscScan: async () => {
      return new Promise((resolve, reject) => {
        let currentContractProperties =
          getState()?.contracts?.currentContractProperties;
        if (currentContractProperties) {
          let requestUrl = `${currentContractProperties.scanURL}/api?module=account&action=tokentx&address=${currentContractProperties.campaignManagement}&startblock=0&endblock=999999999&sort=desc&apikey=${currentContractProperties.API_KEY}`;
          contractProvider
            .getAbi(requestUrl)
            .then((json) => {
              if (json.status === "1") {
                let result = json.result;
                let transactionsTransferToCommunity = result
                  ?.filter((item) => {
                    return (
                      item.contractAddress.toLowerCase() ==
                        currentContractProperties.address.toLowerCase() &&
                      item.to.toLowerCase() ==
                        currentContractProperties.campaignManagement.toLowerCase()
                    );
                  })
                  .map((item, index) => ({ ...item, index: index + 1 }));
                let totalTokenTransferToCommunity =
                  Number(
                    (transactionsTransferToCommunity?.reduce(
                      (a, b) => a + BigInt(b.value),
                      BigInt("0")
                    ) *
                      BigInt(1e8)) /
                      BigInt(1e18)
                  ) / 1e8;
                let transactionsClaimFromCommunity = result?.filter(
                  (item) =>
                    item.contractAddress.toLowerCase() ==
                      currentContractProperties.address.toLowerCase() &&
                    item.from.toLowerCase() ==
                      currentContractProperties.campaignManagement.toLowerCase()
                );
                let totalTokenClaimFromCommunity =
                  Number(
                    (transactionsClaimFromCommunity?.reduce(
                      (a, b) => a + BigInt(b.value),
                      BigInt("0")
                    ) *
                      BigInt(1e8)) /
                      BigInt(1e18)
                  ) / 1e8;

                resolve({
                  transactionsTransferToCommunity,
                  totalTokenTransferToCommunity,
                  transactionsClaimFromCommunity,
                  totalTokenClaimFromCommunity,
                });
              } else {
                resolve([]);
              }
            })
            .catch((err) => {
              dispatch.contracts.updateData({
                balances: [],
              });
              reject("Fail to request!");
            });
        } else {
          dispatch.contracts.updateData({
            balances: [],
          });
          reject("Fail to request!");
        }
      });
    },

    getTokenTx: async (to) => {
      return new Promise((resolve, reject) => {
        let currentContractProperties =
          getState()?.contracts?.currentContractProperties;
        let requestUrl = `${currentContractProperties.scanURL}/api?module=account&action=tokentx&address=${to}&startblock=0&endblock=999999999&sort=desc&apikey=${currentContractProperties.API_KEY}`;
        contractProvider
          .getAbi(requestUrl)
          .then((json) => {
            if (json.status === "1") {
              let result = json.result;
              let data = result;

              resolve(data);
            } else {
              reject("Fail to request!");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  }),
};

export default contracts;
