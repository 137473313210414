import React from "react";
import { DicomViewer } from "./DicomViewer";

function ImageThumbnail({
  stack = {
    seriesDescription: "Helical chest",
    seriesNumber: 2,
    numImageFrames: 100,
  },
  setRender = () => {},
}) {
  return (
    <div
      class="thumbnailEntry"
      onClick={() => {
        if (stack.imageIds[0]) {
          setRender({ data: stack });
        }
      }}
    >
      <div class="imageThumbnail">
        <div
          id="imageThumbnailCanvas{{stack.seriesNumber}}_{{stack.displaySetInstanceUid}}"
          class="imageThumbnailCanvas"
          style={{ display: "flex" }}
        >
          {stack.imageIds[0] ? (
            <DicomViewer
              style={{ width: "100%", height: "100%" }}
              url={stack.imageIds[0]}
            ></DicomViewer>
          ) : (
            <img
              src={require("@assets/images/word.png")}
              style={{
                width: "auto",
                height: "70%",
                alignSelf: "center",
                margin: "0 auto",
              }}
            />
          )}
          {/* <img
            style={{ width: "100%", height: "100%" }}
            src={stack.imageIds[0]?.replace(
              "weburi:/",
              "http://pacsapp.mediplus.vn:741/"
            )}
          /> */}
          {/* {{imageThumbnailCanvas}} */}
        </div>
      </div>
      <div class="seriesDetails">
        <div class="seriesDescription">{stack.seriesDescription}</div>
        <div class="seriesInformation">
          {/* <div class="item item-series clearfix">
            <div class="icon">S:</div>
            <div class="value">{stack.seriesNumber || stack.seriesDir}</div>
          </div> */}
          <div class="item item-frames clearfix">
            <div class="icon">
              <div></div>
            </div>
            <div class="value">
              {stack.numImageFrames || stack.dcmFileCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageThumbnail;
