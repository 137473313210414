import { TABLE_VIEW_MODES } from "@constants/index";
import { POOL_PAYLOAD_TYPES } from "@constants/socket";
import stakingProvider from "@data-access/staking-provider";
import {
  DEFAULT_TABLE_PARAMS,
  STATUS_ACTION_KEYS
} from "@pages/trade/AdminCommunity/Staking/constants";
import {
  getContractReadData,
  getErrorMessage,
  mapPools,
  votingPoolsCustomData,
} from "@utils/index";
import { notification } from "antd";
import { filter, findIndex, isEqual, isNull, map, omitBy } from "lodash";
import useJavaServiceInstance from "../useJavaServiceModel";
const model = "javaStaking";
const provider = stakingProvider;

export const JAVASTAKING_POOLS = {
  votingPools: "votingPools",
  activePools: "activePools",
  rejectInactivePools: "rejectInactivePools",
};

const Utils = {
  fetchPools: async ({ ...restParams }, state) => {
    const { table, params, dispatch } = restParams;
    let errorMsg = null;
    try {
      const { data, message } = await stakingProvider.getPools(
        omitBy(params, isNull)
      );
      const pools = data?.data;
      if (pools) {
        const mappedPools =
          params.view === TABLE_VIEW_MODES.grid ? pools : mapPools(pools);
        let customData = [];
        switch (table) {
          case JAVASTAKING_POOLS.votingPools:
            customData = await votingPoolsCustomData(mappedPools);
            break;
          default:
            customData = mappedPools;
            break;
        }
        const { params: originalParams } = state.javaStaking[table];
        return dispatch[model].updateData({
          [table]: {
            params: originalParams,
            dataSource: customData,
            total: data?.totalElements,
          },
        });
      }
      errorMsg = getErrorMessage("staking", message);
    } catch (error) {
      errorMsg = error?.message || error;
    } finally {
      if (errorMsg) {
        notification.error({
          message: "Error fetching pools",
          description: errorMsg,
        });
      }
    }
  },
};

const staking = useJavaServiceInstance({
  model,
  provider,
  initState: {
    tokens: [],
    contractProperties: {},
    [JAVASTAKING_POOLS.votingPools]: {
      params: { ...DEFAULT_TABLE_PARAMS },
      dataSource: [],
      total: null,
    },
    [JAVASTAKING_POOLS.activePools]: {
      params: { ...DEFAULT_TABLE_PARAMS },
      dataSource: [],
      total: null,
    },
    [JAVASTAKING_POOLS.rejectInactivePools]: {
      params: { ...DEFAULT_TABLE_PARAMS },
      dataSource: [],
      total: null,
    },
  },
  additionEffect: ({ dispatch }) => ({
    setParams: ({ params, table }, state) => {
      return dispatch[model].updateData({
        [table]: {
          ...state.javaStaking[table],
          params: { ...state.javaStaking[table].params, ...params },
        },
      });
    },
    addProposals: (payload, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.votingPools];
      const { dataSource, total, params } = originalState;
      const mappedPayload = mapPools(
        map(payload, (proposal) => ({
          ...proposal,
          actions: {
            delete: false,
            reject: true,
            approve: true,
            release: true,
          },
        }))
      );
      const newDataSource = [...mappedPayload, ...dataSource];
      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.votingPools]: {
          ...originalState,
          dataSource: newDataSource.slice(0, params.size),
          total: total + payload.length,
        },
      });
    },
    updateProposal: async (payload, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.votingPools];
      const { dataSource } = originalState;
      let newDataSource;
      let updatingProposal;

      const index = dataSource.findIndex((item) => {
        if (payload.requestId) {
          return item.requestId === payload.requestId;
        }
        return item.id === payload[0].id;
      });
      if (index === -1) return;

      if (payload.type === POOL_PAYLOAD_TYPES.VOTE_UPDATED) {
        const mappedPayload = filter(
          dataSource,
          (item) => item.requestId === payload.requestId
        );
        const data = await votingPoolsCustomData(mappedPayload);
        updatingProposal = data[0];
      } else {
        const mappedPayload = mapPools(payload);
        const { actions } = dataSource[index];
        updatingProposal = { ...mappedPayload[0], actions };
      }

      newDataSource = Object.assign([...dataSource], {
        [index]: updatingProposal,
      });
      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.votingPools]: {
          ...originalState,
          dataSource: newDataSource,
        },
      });
    },
    addVotingPool: async (payload, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.votingPools];
      const { dataSource, params, total } = originalState;
      let newDataSource;
      let addingItemCount = 0;

      const mappedPayload = mapPools(payload);
      const index = dataSource.findIndex((item) => item.id === payload[0].id);

      const customProposalData = await votingPoolsCustomData(mappedPayload);
      const updatingProposal = customProposalData[0];

      if (index !== -1) {
        newDataSource = Object.assign([...dataSource], {
          [index]: updatingProposal,
        });
      } else {
        newDataSource = [updatingProposal, ...dataSource];
        addingItemCount = 1;
      }

      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.votingPools]: {
          ...originalState,
          dataSource: newDataSource.slice(0, params.size),
          total: total + addingItemCount,
        },
      });
    },
    deleteProposals: (payload, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.votingPools];
      const { dataSource, total, params } = originalState;
      const newDataSource = dataSource.filter(
        (item) => !payload.includes(item.id)
      );

      if (dataSource.length !== newDataSource.length) {
        return dispatch[model].updateData({
          [JAVASTAKING_POOLS.votingPools]: {
            params: {
              ...params,
              page: !newDataSource.length
                ? Math.max(params.page - 1, 0)
                : params.page,
            },
            dataSource: newDataSource,
            total: total - payload.length,
          },
        });
      }
    },
    addActivePool: (payload, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.activePools];
      const { dataSource, total, params } = originalState;
      const { view, size } = params;
      const [pool] = payload;

      const { stakedTokenSymbol, rewardedTokenSymbol } = pool;
      let newDataSource;
      let addingItemCount = 0;
      if (view === TABLE_VIEW_MODES.grid) {
        const index = findIndex(
          dataSource,
          (pools) =>
            pools.stakedTokenSymbol.toLowerCase() ===
              stakedTokenSymbol.toLowerCase() &&
            pools.rewardedTokenSymbol.toLowerCase() ===
              rewardedTokenSymbol.toLowerCase()
        );
        if (index !== -1) {
          newDataSource = Object.assign([...dataSource], {
            [index]: {
              ...dataSource[index],
              pool: [pool, ...dataSource[index].pool],
              count: dataSource[index].count + 1,
            },
          });
        } else {
          const newPoolItems = {
            stakedTokenSymbol,
            rewardedTokenSymbol,
            pool: [pool],
            count: 1,
          };
          newDataSource = [newPoolItems, ...dataSource];
          addingItemCount = 1;
        }
      } else {
        newDataSource = [mapPools([pool])[0], ...dataSource];
        addingItemCount = 1;
      }

      dispatch[model].deleteProposals([pool.id]);
      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.activePools]: {
          ...originalState,
          dataSource: newDataSource.slice(0, size),
          total: total + addingItemCount,
        },
      });
    },
    updatePoolStatistics: async (payload, state) => {
      const { pools } = payload;
      const { id, stakedTokenSymbol, rewardedTokenSymbol } = pools[0];
      const originalState = state.javaStaking[JAVASTAKING_POOLS.activePools];
      const { dataSource, params } = originalState;
      const { view } = params;
      let newDataSource;
      let updatingPoolItem;
      if (view === TABLE_VIEW_MODES.grid) {
        const index = dataSource.findIndex(
          (item) =>
            item.stakedTokenSymbol.toLowerCase() ===
              stakedTokenSymbol.toLowerCase() &&
            item.rewardedTokenSymbol.toLowerCase() ===
              rewardedTokenSymbol.toLowerCase()
        );
        if (index === -1) return;
        const { pool } = dataSource[index];
        const poolIndex = pool.findIndex((item) => item.id === id);
        if (poolIndex === -1) return;
        updatingPoolItem = Object.assign([...pool], {
          [poolIndex]: pools[0],
        });
        newDataSource = Object.assign([...dataSource], {
          [index]: {
            ...dataSource[index],
            pool: updatingPoolItem,
          },
        });
      } else {
        const index = dataSource.findIndex((item) => item.id === id);
        if (index === -1) return;
        updatingPoolItem = Object.assign([...dataSource], {
          [index]: mapPools(pools)[0],
        });
        newDataSource = updatingPoolItem;
      }
      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.activePools]: {
          ...originalState,
          dataSource: newDataSource,
        },
      });
    },
    reactivePools: (payload, state) => {
      const { pools } = payload;
      const originalState = state.javaStaking[JAVASTAKING_POOLS.activePools];
      const { dataSource, params, total } = originalState;

      let newDataSource = [...dataSource];
      let addingItemCount = 0;

      for (const pool of pools) {
        const { stakedTokenSymbol, rewardedTokenSymbol } = pool;
        if (params.view === TABLE_VIEW_MODES.grid) {
          const index = findIndex(
            newDataSource,
            (pools) =>
              pools.stakedTokenSymbol.toLowerCase() ===
                stakedTokenSymbol.toLowerCase() &&
              pools.rewardedTokenSymbol.toLowerCase() ===
                rewardedTokenSymbol.toLowerCase()
          );
          if (index !== -1) {
            newDataSource = Object.assign([...newDataSource], {
              [index]: {
                ...newDataSource[index],
                pool: [pool, ...newDataSource[index].pool],
                count: newDataSource[index].count + 1,
              },
            });
          } else {
            const newPoolItems = {
              stakedTokenSymbol,
              rewardedTokenSymbol,
              pool: [pool],
              count: 1,
            };
            newDataSource = [newPoolItems, ...newDataSource];
            addingItemCount++;
          }
        } else {
          newDataSource = [mapPools([pool])[0], ...newDataSource];
          addingItemCount++;
        }
      }
      const deletingProposalIds = map(pools, (pool) => pool.id);
      dispatch[model].deleteProposals(deletingProposalIds);

      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.activePools]: {
          ...originalState,
          dataSource: newDataSource.slice(0, params.size),
          total: total + addingItemCount,
        },
      });
    },
    deleteActivePools: (pools, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.activePools];
      const { dataSource, params, total } = originalState;
      let newDataSource;

      const deleteIds = map(pools, (pool) => pool.id);
      let deletingItemCount = 0;
      if (params.view === TABLE_VIEW_MODES.grid) {
        newDataSource = map(dataSource, (item) => {
          const { pool } = item;
          const newPools = filter(
            pool,
            (poolItem) => !deleteIds.includes(poolItem.id)
          );
          return {
            ...item,
            pool: newPools,
            count: newPools.length,
          };
        }).filter((item) => {
          const { pool } = item;
          if (pool.length === 0) {
            deletingItemCount++;
            return false;
          }
          return true;
        });
      } else {
        newDataSource = filter(dataSource, (item) => {
          const { id } = item;
          if (deleteIds.includes(id)) {
            deletingItemCount++;
            return false;
          }
          return true;
        });
      }

      if (!isEqual(dataSource, newDataSource)) {
        return dispatch[model].updateData({
          [JAVASTAKING_POOLS.activePools]: {
            params: {
              ...params,
              page: !newDataSource.length
                ? Math.max(params.page - 1, 0)
                : params.page,
            },
            dataSource: newDataSource,
            total: total - deletingItemCount,
          },
        });
      }
    },
    addRejectedInactivatedCompletedPool: (payload, state) => {
      const originalState =
        state.javaStaking[JAVASTAKING_POOLS.rejectInactivePools];
      const { dataSource, total, params } = originalState;
      const { pools } = payload;

      const deletingIds = map(pools, (pool) => pool.id);
      const mappedPools = mapPools(pools);
      let newDataSource = [...dataSource];
      let addingItemCount = 0;

      for (const pool of mappedPools) {
        const index = findIndex(newDataSource, (item) => item.id === pool.id);
        if (index !== -1) {
          newDataSource = Object.assign([...newDataSource], {
            [index]: {
              ...newDataSource[index],
              status: pool.status,
            },
          });
        } else {
          addingItemCount++;
          newDataSource = [pool, ...newDataSource];
        }
      }

      dispatch[model].deleteActivePools(deletingIds);
      dispatch[model].deleteProposals(deletingIds);
      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.rejectInactivePools]: {
          ...originalState,
          dataSource: newDataSource.slice(0, params.size),
          total: total + addingItemCount,
        },
      });
    },
    addRevokingPool: async (payload, state) => {
      const originalState = state.javaStaking[JAVASTAKING_POOLS.votingPools];
      const { dataSource, params, total } = originalState;

      const { pools } = payload;
      const revokingItemData = await votingPoolsCustomData(mapPools(pools));

      dispatch[model].deleteActivePools(pools);
      return dispatch[model].updateData({
        [JAVASTAKING_POOLS.votingPools]: {
          ...originalState,
          dataSource: [...revokingItemData, ...dataSource].slice(
            0,
            params.size
          ),
          total: total + revokingItemData.length,
        },
      });
    },
    fetchPoolsData: async ({ ...params }, state) => {
      await Utils.fetchPools({ ...params, dispatch }, state);
    },
    refetchPoolsData: async ({ table }, state) => {
      const params = {
        table,
        params: state.javaStaking[table].params,
        dispatch,
      };
      await Utils.fetchPools(params, state);
    },
    getTokens: async () => {
      let errorMsg = null;
      try {
        const { data, message } = await stakingProvider.getTokens();
        if (data) {
          const tokens = map(data, (token) => ({
            ...token,
            decimals: parseInt(token.decimals),
            price: token.priceUsd,
          }));
          return dispatch[model].updateData({
            tokens,
          });
        }
        errorMsg = getErrorMessage("staking", message);
      } catch (error) {
        errorMsg = error?.message || error;
      } finally {
        if (errorMsg) {
          notification.error({
            message: "Error fetching tokens",
            description: errorMsg,
          });
        }
      }
    },
    getContractProperties: async () => {
      try {
        const [votingKey, revokingKey] = await Promise.all(
          map(STATUS_ACTION_KEYS, (key) =>
            getContractReadData({
              func: key,
              args: [],
            })
          )
        );
        return dispatch[model].updateData({
          contractProperties: {
            voting: votingKey,
            revoking: revokingKey,
          },
        });
      } catch (error) {
        notification.error({
          message: "Error fetching properties",
          description: error.message,
        });
      }
    },
  }),
});
export default staking;
