const defaultState = { params: { page: 0, size: 9 } };
const datahubService = {
  state: defaultState,
  reducers: {
    updateData(state, payload = {}) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    setParams: (newParams = {}, state) => {
      dispatch.datahubService.updateData({
        params: { ...state.datahubService.params, ...newParams },
      });
    },
  }),
};

export default datahubService;
