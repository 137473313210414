import DotIcon from "@components/DotIcon";
import CellText from "@components/Elements/Table/CellText";
import { formatNumber, getRowStatusVariants } from "@utils/index";
import React from "react";

export { default as PAIR_LOCKED_FACTORY } from "./PairLockedFactory.json";
export { default as VOTE_FACTORY } from "./VoteFactoryV2.json";

export const DEFAULT_TOKEN_ADDRESS =
  process.env.REACT_APP_STAKING_DEFAULT_TOKEN;

export const STAKING_FACTORY_CONTRACT =
  process.env.REACT_APP_STAKING_FACTORY_CONTRACT;

export const VOTE_STAKING_FACTORY = process.env.REACT_APP_VOTE_STAKING_FACTORY;

export const STAKING_DAY_IN_SECONDS = parseInt(
  process.env.REACT_APP_STAKING_DAY_IN_SECONDS
);

export const PROPOSAL_COLUMNS = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    width: 50,
    render: (_, __, index) => <CellText color="black">{index + 1}</CellText>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 80,
    render: (_, record) => {
      let { dotColor, statusText } = getRowStatusVariants(record.status);
      return (
        <CellText color="black" startIcon={<DotIcon color={dotColor} />}>
          {statusText}
        </CellText>
      );
    },
  },
  {
    title: "Period",
    dataIndex: "period",
    key: "period",
    width: 80,
    render: (text) => <CellText color="black">{text} days</CellText>,
  },
  {
    title: "Staked amount",
    dataIndex: "stakedAmount",
    key: "stakedAmount",
    width: 80,
    render: (text, record) => (
      <CellText color="black" weight={500}>
        {formatNumber({ number: text })} {record.stakedTokenSymbol}
      </CellText>
    ),
  },
  {
    title: "Staking value",
    dataIndex: "stakedValue",
    key: "stakedValue",
    width: 80,
    render: (text) => (
      <CellText color="black">{formatNumber({ number: text })} USD</CellText>
    ),
  },
  {
    title: "Rewarded amount",
    dataIndex: "rewardedAmount",
    key: "rewardedAmount",
    width: 80,
    render: (text, record) => (
      <CellText color="black" weight={500}>
        {formatNumber({ number: text })} {record.rewardedTokenSymbol}
      </CellText>
    ),
  },
  {
    title: "Rewarded value",
    dataIndex: "rewardedValue",
    key: "rewardedValue",
    width: 80,
    render: (text) => (
      <CellText color="black">{formatNumber({ number: text })} USD</CellText>
    ),
  },
  {
    title: "Minimum per person",
    dataIndex: "minimumPerPerson",
    key: "minimumPerPerson",
    width: 80,
    render: (text, record) => (
      <CellText color="black">
        {formatNumber({ number: text })} {record.stakedTokenSymbol}
      </CellText>
    ),
  },
  {
    title: "Maximum per person",
    dataIndex: "maximumPerPerson",
    key: "maximumPerPerson",
    width: 80,
    render: (text, record) => (
      <CellText color="black">
        {formatNumber({ number: text })} {record.stakedTokenSymbol}
      </CellText>
    ),
  },
  {
    title: "APR (%)",
    dataIndex: "apr",
    key: "apr",
    width: 80,
    render: (text) => <CellText color="black">{text}</CellText>,
  },
  {
    title: "APY (%)",
    dataIndex: "apy",
    key: "apy",
    width: 80,
    render: (text) => <CellText color="black">{text}</CellText>,
  },
];

export const CREATION_MODE = {
  single: "SINGLE",
  multiple: "MULTIPLE",
};

export const PRIORITY_PERIODS = [30, 60, 90];

export const POOL_STATUS = {
  DRAFT: "DRAFT",
  VOTING: "VOTING",
  REVOKING: "REVOKING",
  ACTIVE: "ACTIVE",
  REJECTED: "REJECTED",
  INACTIVE: "INACTIVE",
  COMPLETED: "COMPLETED",
};

export const POOL_STAKE_STATUS = {
  AVAILABLE: "available",
  OUT_OF_QUOTA: "outofquota",
};

export const STATUS_ACTION_KEYS = [
  "PAIR_LOCKED_FACTORY_KEY",
  "PAIR_LOCKED_FACTORY_REVOKE_KEY",
];

export const VOTE_TYPE = {
  ACCEPT: 1,
  REJECT: 2,
};

export const CONSENSUS_THRESHOLD = 0.51;

export const DEFAULT_TABLE_PARAMS = {
  page: 0,
  size: 10,
  orderBy: "status",
  orderDirection: "asc",
};

export const ANTD_SORT_ORDERS = {
  asc: "ascend",
  desc: "descend",
};

export const PROPOSAL_VIEW_MODES = {
  confirm: "confirm",
  viewDetails: "viewDetails",
};

export const MAX_INT_VALUE = 2147483647 - 1; // Subtract 1 because max value is not divisible by 3

export const TABLE_REMOVE_EMPTY = {
  emptyText: " ",
};
