import { theme } from "@src/styles/themes";

theme.typography.fontSize = 14;

theme.typography.h3 = {
  [theme.breakpoints.down("md")]: {
    fontSize: 24,
  },
};

export { Typography as CustomTypography } from "@mui/material";
