export { REGEX_NUMBER_ONLY } from "./regexs";

export const theme = {};

export const assetStatusDatamarket = {
  CANBUY: 10,
  AUCTION: 20,
  GRANTED: 30,
  REPORTED: 40,
};

export const ASSET_REQUEST_STATUS = {
  0: "Pending",
  1: "Declined",
  2: "Accepted",
  3: "Accused Successfully",
  4: "Response expired",
  5: "Accuse time expired",
};

export const getStatusByCode = (status) => {
  switch (status) {
    case 1:
      return "Listed";
    case 2:
      return "Owner";
    case 3:
      return "Granted";
    case 4:
      return "Reported";
    default:
      return "";
  }
};

export const DatahubAssetConst = {
  MEDICAL_UNIT: {
    filter: [{ label: "EMR", value: 0 }],
    mapping: {
      0: "EMR",
    },
  },
};

export const DatahubNotiStatus = {
  READ: 10,
  UNREAD: 20,
};

export const DatahubNotiTypeEnum = {
  WHEN_USER_RECEIVED_REQUEST_ACCESS_TO_DATA_BY_ANY_PURCHASER: 10,
  WHEN_RECEIVED_DATA_REPORT_RESULT: 20,
  WHEN_OWNER_REJECTED_REQUEST_ACCESS_DATA: 30,
  WHEN_OWNER_ACCEPTED_REQUEST_ACCESS_DATA: 40,
  "WHEN_THE_REPORT_RESULTS_CONFIRM_A_DATA_ERROR_(REPORT_SUCCESSFULLY)": 50,
  "WHEN_THE_REPORT_RESULTS_CONFIRM_THERE’S_NO_DATA_ERROR_(REPORT_FAILED)": 60,
  WHEN_USER_DEPOSIT_TO_SMART_CONTRACT_BALANCE_SUCCESSFULLY: 70,
  WHEN_USER_WITHDRAW_FROM_SMART_CONTRACT_BALANCE_TO_WALLET_SUCCESSFULLY: 80,
  WHEN_USER_LISTING_DATA_SUCCESSFULLY: 90,
};

export const TypeVendor = {
  IVIE: {
    vendor: "IVIE",
    image: require("@images/trade/datahub/item-example-image.png"),
    icon: require("@assets/images/ivie-filter-logo.png"),
    background: "linear-gradient(123.23deg, #CECECE -16.4%, #538A76 71.36%)",
    thumbLogo: require("@assets/images/ivie-logo.png"),
    name: "marketplace.filter.ivie",
  },
  OOMY: {
    vendor: "OOMY",
    image: require("@images/trade/datahub/item-oomy-image.png"),
    icon: require("@assets/images/oomy-filter-logo.png"),
    background: "linear-gradient(123.23deg, #6D7CFF -16.4%, #6A41BF 71.36%)",
    thumbLogo: require("@assets/images/oomy-logo.png"),
    name: "marketplace.filter.oomy",
  },
  MEDI: {
    vendor: "MEDI",
    image: require("@images/trade/datahub/item-medi-image.png"),
    icon: require("@assets/images/medi-filter-logo.png"),
    background: "linear-gradient(123.23deg, #9BB5C4 -16.4%, #2F7AAC 71.36%)",
    thumbLogo: require("@assets/images/medi-logo.png"),
    name: "marketplace.filter.medi",
  },
  ["undefined"]: {
    vendor: "IVIE",
    image: require("@images/trade/datahub/item-example-image.png"),
    icon: require("@assets/images/ivie-filter-logo.png"),
    background: "linear-gradient(123.23deg, #CECECE -16.4%, #538A76 71.36%)",
    thumbLogo: require("@assets/images/ivie-logo.png"),
    name: "marketplace.filter.ivie",
  },
};

export const logImages = [
  { icon: require("@assets/images/log/log-1.png"), content: "fantastic" },
  { icon: require("@assets/images/log/log-2.png"), content: "managable" },
  { icon: require("@assets/images/log/log-3.png"), content: "long" },
  { icon: require("@assets/images/log/log-4.png"), content: "horrible" },
  { icon: require("@assets/images/log/log-5.png"), content: "happy" },
  { icon: require("@assets/images/log/log-6.png"), content: "calm" },
  { icon: require("@assets/images/log/log-7.png"), content: "dull" },
  { icon: require("@assets/images/log/log-8.png"), content: "sad" },
  { icon: require("@assets/images/log/log-9.png"), content: "awesome" },
  { icon: require("@assets/images/log/log-10.png"), content: "ok" },
  { icon: require("@assets/images/log/log-11.png"), content: "bad" },
  { icon: require("@assets/images/log/log-12.png"), content: "terrible" },
  { icon: require("@assets/images/log/log-13.png"), content: "energetic" },
  { icon: require("@assets/images/log/log-14.png"), content: "ok" },
  { icon: require("@assets/images/log/log-15.png"), content: "tired" },
  { icon: require("@assets/images/log/log-16.png"), content: "exhausted" },
  { icon: require("@assets/images/log/log-17.png"), content: "delicious" },
  { icon: require("@assets/images/log/log-18.png"), content: "usual" },
  { icon: require("@assets/images/log/log-19.png"), content: "less" },
  { icon: require("@assets/images/log/log-20.png"), content: "none" },
  { icon: require("@assets/images/log/log-21.png"), content: "confident" },
  { icon: require("@assets/images/log/log-22.png"), content: "skeptical" },
  { icon: require("@assets/images/log/log-23.png"), content: "doubtful" },
  { icon: require("@assets/images/log/log-24.png"), content: "self" },
  { icon: require("@assets/images/log/log-25.png"), content: "productive" },
  { icon: require("@assets/images/log/log-26.png"), content: "less" },
  {
    icon: require("@assets/images/log/log-27.png"),
    content: "easilyDistracted",
  },
  {
    icon: require("@assets/images/log/log-28.png"),
    content: "unableConcentrate",
  },
  { icon: require("@assets/images/log/log-29.png"), content: "normal" },
  {
    icon: require("@assets/images/log/log-30.png"),
    content: "aLittleUnusual",
  },
  { icon: require("@assets/images/log/log-31.png"), content: "unusual" },
  {
    icon: require("@assets/images/log/log-32.png"),
    content: "extremelyUnusual",
  },
  { icon: require("@assets/images/log/log-33.png"), content: "never" },
  { icon: require("@assets/images/log/log-34.png"), content: "once" },
  { icon: require("@assets/images/log/log-35.png"), content: "sometimes" },
  { icon: require("@assets/images/log/log-36.png"), content: "usually" },
];
export const listQuestion = [
  {
    title: "day",
    stepName: "day",
    field: "phq8_day",
    list: [logImages[0], logImages[1], logImages[2], logImages[3]],
  },
  {
    title: "mood",
    stepName: "mood",
    field: "phq8_mood",
    list: [logImages[4], logImages[5], logImages[6], logImages[7]],
  },
  {
    title: "sleep",
    stepName: "sleep",
    field: "phq8_sleep",
    list: [logImages[8], logImages[9], logImages[10], logImages[11]],
  },
  {
    title: "energy",
    stepName: "energy",
    field: "phq8_energy",
    list: [logImages[12], logImages[13], logImages[14], logImages[15]],
  },
  {
    title: "appetite",
    stepName: "appetite",
    field: "phq8_appetite",
    list: [logImages[16], logImages[17], logImages[18], logImages[19]],
  },
  {
    title: "selfReflection",
    stepName: "self",
    field: "phq8_self",
    list: [logImages[20], logImages[21], logImages[22], logImages[23]],
  },
  {
    title: "productivity",
    stepName: "prod",
    field: "phq8_prod",
    list: [logImages[24], logImages[25], logImages[26], logImages[27]],
  },
  {
    title: "motionLevel",
    stepName: "motion",
    field: "phq8_motion",
    list: [logImages[28], logImages[29], logImages[30], logImages[31]],
  },
  {
    title: "selfHarm",
    stepName: "selfHarm",
    field: "phq9_selfHarm",
    list: [logImages[32], logImages[33], logImages[34], logImages[35]],
  },
];

export const EntryLogic = [
  { name: "oomy.question.healthy", point: "0-4", from: 0, to: 4 },
  { name: "oomy.question.mild", point: "5-9", from: 5, to: 9 },
  { name: "oomy.question.moderate", point: "10-14", from: 10, to: 14 },
  { name: "oomy.question.moderatelySevere", point: "15-19", from: 15, to: 19 },
  { name: "oomy.question.severe", point: "20-27", from: 20, to: 27 },
];

export const VENDORS = {
  oomy: "OOMY",
  ivie: "IVIE",
  medi: "MEDI",
};

export const DEFAULT_OOMY_ICD_PARENT_CODE = 'V'

export const DEFAULT_OOMY_ICD_OPTIONS = [
  "F30",
  "F32.0",
  "F32.1",
  "F32.2",
  "F32.3",
];

export const HTTP_RESPONSE_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,

  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,

  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,

  PERIOD_ALREADY_EXIST: 4001,
};

export const FORMATTING_TYPES = {
  PERCENT: "0,0%",
  PERCENT_2_DECIMAL_DIGITS: "0,0.[00]%",
  PERCENT_4_DECIMAL_DIGITS: "0,0.[0000]%",
  PERCENT_6_DECIMAL_DIGITS: "0,0.[000000]%",
  PERCENT_9_DECIMAL_DIGITS: "0,0.[000000000]%",

  NUMBER: "0,0",
  NUMBER_2_DECIMAL_DIGITS: "0,0.[00]",
  NUMBER_4_DECIMAL_DIGITS: "0,0.[0000]",
  NUMBER_6_DECIMAL_DIGITS: "0,0.[000000]",
  NUMBER_9_DECIMAL_DIGITS: "0,0.[000000000]",
};

export const TABLE_VIEW_MODES = {
  grid: "grid",
  list: "list",
};

export const BC_FUNCTIONS = {
  REJECT: "reject",
  REJECT_BATCH: "rejectBatch",
  ACCEPT: "accept",
  ACCEPT_BATCH: "acceptBatch",
  ACTIVE_BATCH: "activeBatch",
  REVOKE_BATCH: "revokeBatch",
  GET_TOTAL_VOTE: "getTotalVote",
  GET_ACCOUNT_VOTE: "getAccountVote",

  ALLOWANCE: "allowance",

  GET_STAKE_IDS: "getStakeIds",
  SUBSCRIBE: "subscribe",
  CLAIM_REWARD: "claimReward",
  REDEEM_STAKED_ASSET: "redeemStakedAsset",
};

export const NUMBER_RUES_INPUT = {
  listData: {
    cid: {
      min: 6,
      max: 125,
    },
    description: {
      min: 6,
      max: 1000,
    },
  },
  proposal: {
    decimalScale: 6,
    max: 999999.999999,
  },
  stakeAmount: {
    decimalScale: 6,
    max: 99999999.999999,
    min: 0,
  },
};

export const DEFAULT_TIMEZONE = "Asia/Bangkok";
export const NOT_AVAILABLE_TEXT = "N/A";
export const MIN_NUMBER_ALLOWED = 0.001;
export const CHART_EMPTY_COLOR = "#644f83";