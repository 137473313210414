import clientUtils from "@utils/client-utils";
import queryString from "query-string";
import baseProvider from "./base-provider";

const baseUrl = "/staking";

const stakingProvider = {
  ...baseProvider(baseUrl),
  getPeriods: async () => {
    const url = `${baseUrl}/admin/periods`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },
  createPeriod: async (body) => {
    const url = `${baseUrl}/admin/periods`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "POST",
        url,
        isUseServiceUrl: true,
        body,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },
  deletePeriod: async (id) => {
    const url = `${baseUrl}/admin/periods/${id}`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "DELETE",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getPairStatistics: async (stakedToken, rewardedToken) => {
    const prefixUrl = `${baseUrl}/admin/metrics/statistics`;
    const queryParams = {
      stakedToken,
      rewardedToken,
    };
    const url = `${prefixUrl}?${queryString.stringify(queryParams)}`;

    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  createProposals: async (proposal) => {
    const url = `${baseUrl}/admin/pools/proposals`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "POST",
        url,
        isUseServiceUrl: true,
        body: {
          proposal,
        },
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  updateProposal: async (id, proposal) => {
    const url = `${baseUrl}/admin/pools/proposals/${id}`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "PUT",
        url,
        isUseServiceUrl: true,
        body: proposal,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  deleteProposals: async (ids) => {
    const url = `${baseUrl}/admin/pools/proposals`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "DELETE",
        url,
        body: {
          proposals: ids,
        },
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getTokens: async () => {
    const url = "/tokenprice/tokens";
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  searchPools: async (queryParams) => {
    const prefixUrl = `${baseUrl}/admin/pools/search`;
    const url = `${prefixUrl}?${queryString.stringify(queryParams)}`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getPools: async (queryParams) => {
    const prefixUrl = `${baseUrl}/admin/pools`;
    const url = `${prefixUrl}?${queryString.stringify(queryParams)}`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  revokePools: async (ids) => {
    const url = `${baseUrl}/admin/pools/revoke`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "PUT",
        url,
        body: {
          pools: ids,
        },
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  returnRevokedPools: async (ids) => {
    const url = `${baseUrl}/admin/pools/return`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "PUT",
        url,
        body: {
          pools: ids,
        },
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getStakingOverview: async () => {
    const url = `${baseUrl}/admin/metrics/staking-overview`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getTokenMasterSMCReward: async () => {
    const url = `${baseUrl}/admin/metrics/master-smc-for-reward`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getTotalCirculatingSupply: async () => {
    const url = `${baseUrl}/admin/metrics/total-circulating-supply`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getAllocatedReward: async (token) => {
    const prefixUrl = `${baseUrl}/admin/metrics/allocated-reward`;
    const queryParams = {
      token,
    };
    const url = `${prefixUrl}?${queryString.stringify(queryParams)}`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },

  getUserPools: async (queryParams) => {
    const { poolViewEndpoint, ...restParams } = queryParams;
    const prefixUrl = `${baseUrl}/user/${poolViewEndpoint}`;
    const url = `${prefixUrl}?${queryString.stringify({
      ...restParams,
    })}`;
    try {
      const res = await clientUtils.requestApi({
        ignoreAuth: false,
        methodType: "GET",
        url,
        isUseServiceUrl: true,
      });
      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default stakingProvider;
