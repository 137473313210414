import { Spin } from "antd";
import React from "react";
import { ButtonStyled, Icon } from "./styled";
import { LoadingOutlined } from "@ant-design/icons";

const spinSizes = {
  sm: "small",
  md: "default",
  lg: "large",
};

const antIcon = <LoadingOutlined spin />;

export default function Button({
  color = "violet",
  size = "md",
  variant = "solid",
  type = "button",
  shape,
  startIcon,
  endIcon,
  isLoading,
  spinner = <Spin indicator={antIcon} size={spinSizes[size]} />,
  children,
  loadingText = children,
  ...otherProps
}) {
  return (
    <ButtonStyled
      color={color}
      size={size}
      variant={variant}
      shape={shape}
      type={type}
      $loading={isLoading}
      {...otherProps}
    >
      {isLoading && spinner}
      {startIcon && (
        <Icon $startIcon={!!startIcon} size={size}>
          {startIcon}
        </Icon>
      )}
      {isLoading ? loadingText : children}
      {endIcon && (
        <Icon $endIcon={!!endIcon} size={size}>
          {endIcon}
        </Icon>
      )}
    </ButtonStyled>
  );
}
