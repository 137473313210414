export const colorShamrock400 = "#61CA96";

export const colorSilverTree400 = "#56B988";

export const whiteColor = "#ffffff";

export const blackColor = "#000000";

export const colorMineShaft950 = "#2b2b2b";

export const colorHaiti950 = "#1C0C31";

export const colorAzureRadiance600 = "#1b76ff";

export const colorSilver300 = "#C6C6C6";
export const colorSilver400 = "#C2C2C2";

export const colorWildSand50 = "#F5F5F5";

export const colorDoveGray500 = "#6E6E6E";

export const colorGallery100 = "#EFEFEF";

export const colorRed700 = "#EA0000";

export const colorAmethyst600 = "#9B5FCC";

export const colorFuchsiaBlue600 = "#6e5ac3";

export const colorValhalla950 = "#30164f";

export const colorRevolver950 = "#2a1935";

export const colorPurpleHeart800 = "#6828a4";

export const colorJade500 = "#2EA96C";

export const colorChetwodeBlue500 = "#9a89e1";

export const colorAquaSpring50 = "#ECF9F4";
export const colorAquaSpring100 = "#d4f3e8";
