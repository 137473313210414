export const IPFS_SERVER_DEV = "https://ds0.ivirse.com/api/v0";

export const ROOT_PEM = `-----BEGIN CERTIFICATE-----
MIIDaDCCAlCgAwIBAgIUXrc7U9fGqFd/zIvPnPZMbSrIb+0wDQYJKoZIhvcNAQEL
BQAwTDELMAkGA1UEBhMCRlIxETAPBgNVBAcTCFRvdWxvdXNlMRgwFgYDVQQLEw9B
Q01FIG9wZXJhdGlvbnMxEDAOBgNVBAMTB1Jvb3QgQ0EwHhcNMjIwNjA4MDcxMjAw
WhcNMjcwNjA3MDcxMjAwWjBMMQswCQYDVQQGEwJGUjERMA8GA1UEBxMIVG91bG91
c2UxGDAWBgNVBAsTD0FDTUUgb3BlcmF0aW9uczEQMA4GA1UEAxMHUm9vdCBDQTCC
ASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBALJ2KreaZfne7/PQ4DnZdU7A
NE4dId9WfyJ7K33IS/LXQwW/BapKHvDc9HfjX9WeB5ghPVCWl12VJMbbovRJNVtB
8++dKZviJJOzBYfF0tthBuDCUME2g4mIxIA736GDNXKGLXKvNObbRfsEAjBJJsVM
9sYo4HzywHXGYe0XjMDnS6yXb+mHWGyKbdPG/l1aovIJDDdcKQq80+EROF+CGN/W
Qh9hnS1iE/2E+6lgIcSLqOoB+O4B1vJLF8V5XCatoIxCmq8ABP2fz/MKGsErxiaW
/JkHwLv/phxZlRy3CC0So6pTqiSOrTmLxqN153oLs7Kn2uOIX5F3vyXO03yVUJEC
AwEAAaNCMEAwDgYDVR0PAQH/BAQDAgEGMA8GA1UdEwEB/wQFMAMBAf8wHQYDVR0O
BBYEFCuHgQemhDbzkbt0H2gDMjv65JVHMA0GCSqGSIb3DQEBCwUAA4IBAQADf5rv
9T8jEBMbkvyodzX52pSBvyNLn806fuDurKWN9cOswaLeQNrAzIfuU6jqogPwHgs2
Y45FnDSgQvikoJUC+hlxH31IDtZmq2XeikMHDEzFpeakoAkhSDxy3xRUqES4U4LT
uVDCe+SdO8ZQbl29pjNOpTwK7c4T8+qd3MnEjLxqOeNlhK6liGr0uuFQqpR+KndQ
QX47vaHmNw+sjsn3kAD1TClBU0g1FQsrgWfB6vfhYv4JTCiAgvtQqMu7WGlM2Erg
rOMYMsDAB7K4nu3Y9TLGPPiu7OuGGHpPIB9vJNRk0i8y8uGlz0F4KTH+fOVp2DCq
y8CH9L7VaUFRXTnv
-----END CERTIFICATE-----`;

export const ROOT_PEM2 =
  "-----BEGIN CERTIFICATE-----\nMIIEezCCA2OgAwIBAgIUHpdS/ZnSHQhquT3kH+Prad9XYJkwDQYJKoZIhvcNAQEL\nBQAwfjELMAkGA1UEBhMCVVMxEzARBgNVBAgTCkNhbGlmb3JuaWExFjAUBgNVBAcT\nDVNhbiBGcmFuY2lzY28xKDAmBgNVBAsTH0Ryb3Bzb25kZSBDZXJ0aWZpY2F0ZSBB\ndXRob3JpdHkxGDAWBgNVBAMTD0ludGVybWVkaWF0ZSBDQTAeFw0yMjA5MTMxMTMy\nMDBaFw0yNTA5MTIxMTMyMDBaMG0xCzAJBgNVBAYTAlZOMQswCQYDVQQIEwJITjEV\nMBMGA1UEBxMMSGFpIEJhIFRydW5nMQ8wDQYDVQQKEwZJVklSU0UxDDAKBgNVBAsT\nA0lWUzEbMBkGA1UEAxMSSW50ZWdyYXRpb24gU2VydmVyMIIBIjANBgkqhkiG9w0B\nAQEFAAOCAQ8AMIIBCgKCAQEAwc2+fak/spEs7UmKy5cpTAua3CosfETqzXhss9e+\nD6DGPeONIZ4uBBQkC5Ut2dQzCPNapVzMTKQqNzcOBSsokfGkb2XZKqbY0mK4xXRo\nkJwQG1twKqbqjBKYDgq3RqC4/c4FvblFKB2ZOJey0t5za3YJQEaP0B8hBExtK4Qv\n2D8leSdzu5C368oMsN2n/ZeA0YmDSCzQxx3QqcPIPhxB/AZojR+bwFcquOZbH5MF\nA61iVmK2YDvSCObmrIuLTQR7Zb6QdcexCM1FVr6V6/Q04SSOZAOCDAsquOSYyBWF\nfg9rSw2bek5X5IHMNb63LsR9vHuOuVx8IpEZCQVz8jdS0wIDAQABo4IBADCB/TAO\nBgNVHQ8BAf8EBAMCAaYwEwYDVR0lBAwwCgYIKwYBBQUHAwEwDwYDVR0TAQH/BAUw\nAwEB/zAdBgNVHQ4EFgQUnLXdtkhL7Yb9TvA3F8iNxPqLjXAwHwYDVR0jBBgwFoAU\nJ1po1Wec9QTWK5ye4H9a4e82eTowWQYIKwYBBQUHAQEETTBLMCYGCCsGAQUFBzAB\nhhpodHRwczovL2NhLml2aXJzZS5jb20vb2NzcDAhBggrBgEFBQcwAoYVaHR0cHM6\nLy9jYS5pdmlyc2UuY29tMCoGA1UdHwQjMCEwH6AdoBuGGWh0dHBzOi8vY2EuaXZp\ncnNlLmNvbS9jcmwwDQYJKoZIhvcNAQELBQADggEBAF9PLJiBQmgLwBs691WC1uR1\n0FUJIJ+qu5GRzFec7l8iP7CR7WSVktIs0+d9dz5quanvbskc8hvFK5uvBzCcXtQj\nHyZb3aCOpwUSqsq2+ZanXLJOpGvy+faflA2X+4/R/Ub3NsWSqfPqd/WG8pJPDMO2\nO8zzh1TNYmLzSsMlJ+Rx2WAz4VLN4mBIxNcS+3mKu/OsZd2bhFmB6oFDl6Ib7j+5\nVFdNwan6770CixOvojjU5QTNYQUSrwyHhcqamavqoxxDAQdoEyNExWxKetusPrfR\nniLioAw2tiR8GiZ7e1ej/qspLnn3mqDxFIrR1mvHIyk46cgyb9WdGgzTa3CgRtA=\n-----END CERTIFICATE-----\n";
const buyerDir = "buyer";
const ownerDir = "owner";
export const ownerStorageConfig = {
  local: {
    type: "leveldb",
    options: {
      dir: ownerDir,
    },
  },
  distributedStorage: {
    type: "ipfs",
    options: {
      ipfsDaemonUrl: IPFS_SERVER_DEV,
    },
  },
};
export const buyerStorageConfig = {
  local: {
    type: "leveldb",
    options: {
      dir: buyerDir,
    },
  },
  distributedStorage: {
    type: "ipfs",
    options: {
      ipfsDaemonUrl: IPFS_SERVER_DEV,
    },
  },
};
