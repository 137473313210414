import { Contract } from "ethers";

import CAMPAIGN_MANAGEMENT_BUILDER from "./build/CampaignManagement.json";
import CONSENSUS_MANAGEMENT_BUILDER from "./build/ConsensusManagement.json";
import VOTE_FACTORY_BUILDER from "./build/VoteFactory.json";

class ConsensusService {
  constructor(
    signer,
    consensusManagementContract,
    voteFactoryContract,
    campaignManagementContract
  ) {
    this.signer = signer;
    this.consensusManagementContract = consensusManagementContract;
    this.voteFactoryContract = voteFactoryContract;
    this.campaignManagementContract = campaignManagementContract;
  }

  static async init(
    signer,
    consensusManagementAddress,
    voteFactoryAddress,
    campaignManagementAddress
  ) {
    const consensusManagementContract = new Contract(
      consensusManagementAddress,
      CONSENSUS_MANAGEMENT_BUILDER.abi,
      signer.provider
    );

    const voteFactoryContract = new Contract(
      voteFactoryAddress,
      VOTE_FACTORY_BUILDER.abi,
      signer.provider
    );

    const campaignManagementContract = new Contract(
      campaignManagementAddress,
      CAMPAIGN_MANAGEMENT_BUILDER.abi,
      signer.provider
    );

    return new ConsensusService(
      signer,
      consensusManagementContract,
      voteFactoryContract,
      campaignManagementContract
    );
  }
  /**
   * @Admin
   */
  async getCurrentRole() {
    let address = await this.signer.getAddress();
    let isParticipant = await this.campaignManagementContract
      .connect(this.signer)
      .isParticipant(address);
    let isMasterAdmin = await this.consensusManagementContract
      .connect(this.signer)
      .isMasterAdmin(address);
    let isAdmin = await this.consensusManagementContract
      .connect(this.signer)
      .isAdmin(address);
    return { isParticipant, isAdmin, isMasterAdmin };
  }

  async getTotalAcceptAdminVote(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getTotalAdminVote(account, 1);
  }

  async getTotalRejectAdminVote(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getTotalAdminVote(account, 2);
  }

  async getCurrentAccountVoteForAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getAdminVote(account, await this.signer.getAddress());
  }

  async getAdminStatus(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getAdminStatus(account);
  }
  async getMasterAdminStatus(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getMasterAdminStatus(account);
  }
  async renounceAdminRole() {
    return await this.consensusManagementContract
      .connect(this.signer)
      .renounceAdminRole();
  }
  async proposeNewAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .proposeNewAdmin(account);
  }

  async acceptAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .acceptAdmin(account);
  }

  async rejectAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .rejectAdmin(account);
  }

  async grantAdminRole(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .grantAdminRole(account);
  }

  async revokeAdminRole(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .revokeAdminRole(account);
  }
  async removeAccount(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .removeAccount(account);
  }

  async getAllAdmin() {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getAllListRequestAdmin();
  }

  async getRequired() {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getRequired();
  }

  async countActiveAdmin() {
    return await this.consensusManagementContract
      .connect(this.signer)
      .countActiveAdmin();
  }
  async removeAccountFromAdminList(address) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .removeAccountFromAdminList(address);
  }

  /**
   * @MasterAdmin
   */

  async getTotalAcceptMasterAdminVote(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getTotalMasterAdminVote(account, 1);
  }

  async getTotalAcceptMasterAdminVote(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getTotalMasterAdminVote(account, 1);
  }

  async getTotalRejectMasterAdminVote(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getTotalMasterAdminVote(account, 2);
  }

  async proposeNewMasterAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .proposeNewMasterAdmin(account);
  }
  async acceptMasterAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .acceptMasterAdmin(account);
  }

  async rejectMasterAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .rejectMasterAdmin(account);
  }

  async grantMasterAdminRole(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .grantMasterAdminRole(account);
  }

  async getMasterAdmin() {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getMasterAdmin();
  }

  async getAllListRequestMasterAdmin() {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getAllListRequestMasterAdmin();
  }

  async getCurrentAccountVoteMasterAdmin(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .getMasterAdminVote(account, await this.signer.getAddress());
  }

  async removeAccountFromMasterAdminList(address) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .removeAccountFromMasterAdminList(address);
  }

  async revokeMasterAdminRole(account) {
    return await this.consensusManagementContract
      .connect(this.signer)
      .revokeMasterAdminRole(account);
  }
  /**
   * @Campaign
   */

  async acceptCampaign(campaignId) {
    const CAMPAIGN_ACTION = await this.campaignManagementContract
      .connect(this.signer)
      .CAMPAIGN();
    return await this.voteFactoryContract
      .connect(this.signer)
      .accept(CAMPAIGN_ACTION, campaignId);
  }

  async rejectCampaign(campaignId) {
    const CAMPAIGN_ACTION = await this.campaignManagementContract
      .connect(this.signer)
      .CAMPAIGN();
    return await this.voteFactoryContract
      .connect(this.signer)
      .reject(CAMPAIGN_ACTION, campaignId);
  }

  async requestNewCampaign(name, participants) {
    return await this.campaignManagementContract
      .connect(this.signer)
      .requestNewCampaign(name, participants);
  }

  async release(campaignId) {
    return await this.campaignManagementContract
      .connect(this.signer)
      .release(campaignId);
  }

  async inactiveRequest(campaignId) {
    return await this.campaignManagementContract
      .connect(this.signer)
      .inactiveRequest(campaignId);
  }

  async getBalance(account) {
    return await this.campaignManagementContract
      .connect(this.signer)
      .getBalance(account);
  }

  async getCurrentBalance() {
    return await this.campaignManagementContract
      .connect(this.signer)
      .getBalance(await this.signer.getAddress());
  }

  async claimReward() {
    return await this.campaignManagementContract
      .connect(this.signer)
      .claimReward();
  }

  async getCampaignTotalVote(id, voteType) {
    const CAMPAIGN_ACTION = await this.campaignManagementContract
      .connect(this.signer)
      .CAMPAIGN();
    return await this.voteFactoryContract
      .connect(this.signer)
      .getTotalVote(CAMPAIGN_ACTION, id, voteType);
  }

  async getAllCampaign() {
    return await this.campaignManagementContract
      .connect(this.signer)
      .getAllCampaign();
  }

  async getCountCampaign() {
    return await this.campaignManagementContract
      .connect(this.signer)
      .getCountCampaign();
  }
  async getCurrentAccountCampaignVote(id) {
    const CAMPAIGN_ACTION = await this.campaignManagementContract
      .connect(this.signer)
      .CAMPAIGN();
    return await this.voteFactoryContract
      .connect(this.signer)
      .getAccountVote(CAMPAIGN_ACTION, id, await this.signer.getAddress());
  }
}

export default ConsensusService;
