import { CheckCircleOutlined } from "@ant-design/icons";
import { CustomTypography } from "@components/Elements/Typography";
import { MenuModeEnum } from "@layouts/trade/header";
import { MenuVerticalComponents } from "@layouts/trade/header/menuVerStyled";
import {
  generateDefaultOpenKeys,
  generateMenu,
  getTradeHeaderItems,
} from "@layouts/trade/header/utils";
import {
  colorFuchsiaBlue600,
  colorMineShaft950,
  whiteColor,
} from "@src/styles/modules/colors";
import { formatPrice } from "@utils/index";
import { Avatar, Tooltip } from "antd";
import { filter } from "lodash";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAccount, useBalance, useNetwork } from "wagmi";

export const AccountPanelHead = () => {
  const { address } = useAccount();
  const { data } = useBalance({ address });
  const { chain } = useNetwork();

  return (
    <div className="account-panel__header">
      <div className="account-info">
        <AccountAvatarStyled className="account-info__avatar">
          <Avatar src={`https://robohash.org/${address}`} />
          <AdminVerifyIconStyled />
        </AccountAvatarStyled>
        <div className="account-info__txt">
          <Tooltip title={address} placement="bottomLeft">
            <CustomTypography
              className="account-info__name"
              component="p"
              fontWeight={600}
            >
              {address}
            </CustomTypography>
          </Tooltip>
          <p className="account-info__asset">
            {formatPrice(data?.formatted)}
            <span> {chain?.nativeCurrency?.symbol}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const AccountAvatarStyled = styled.div`
  position: relative;
  margin-right: 12px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid ${colorFuchsiaBlue600};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
`;

const AdminVerifyIconStyled = styled(CheckCircleOutlined)`
  position: absolute;
  bottom: -3px;
  right: -3px;
  font-size: 18px;
  color: ${colorFuchsiaBlue600};
  background-color: ${whiteColor};
  border-radius: 50%;
`;

export const AccountPanelBody = ({ history }) => {
  const { pathname, search } = useLocation();

  const menus = filter(
    getTradeHeaderItems({ history }),
    (item) => item.inSettingOnly
  );

  const defaultOpenKeys = generateDefaultOpenKeys(menus, pathname);

  return (
    <MenuVerticalComponents.NavMenu
      mode={MenuModeEnum.Inline}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultOpenKeys}
      className="ant-menu-sub-bg-grey"
    >
      {generateMenu({
        data: menus,
        variant: true,
        path: `${pathname}${search}`,
        ParentComponent: MenuVerticalComponents,
        subMenuTextProps: {
          color: colorMineShaft950,
        },
        subMenuProps: {
          color: colorMineShaft950,
          hoverColor: colorMineShaft950,
        },
        menuItemProps: {
          color: colorMineShaft950,
          hoverColor: colorMineShaft950,
        },
      })}
    </MenuVerticalComponents.NavMenu>
  );
};
