import { GlobalIcon } from "@assets/svg";
import { CustomTypography } from "@components/Elements/Typography";
import useLocalStorage from "@hook/useLocalStorage";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ChangeLanguageWrapper,
  DropDownGlobalStyled,
  StackSettingItemStyled,
} from "./styled";

function ChangeLanguage() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [value, setValue] = useLocalStorage("selectedKeys", ["en"]);

  useEffect(() => {
    if (value) {
      changeLanguage(value);
    }
  }, [value]);
  const items = [
    {
      key: "vi",
      label: "Vietnamese",
    },
    {
      key: "en",
      label: "English",
    },
  ];
  const handleChangeLanguage = (language) => {
    let languageKey = language.key;
    setValue([languageKey]);
    changeLanguage(languageKey);
  };
  return (
    <>
      <DropDownGlobalStyled />{" "}
      <ChangeLanguageWrapper
        menu={{
          items,
          selectedKeys: value,
          onClick: handleChangeLanguage,
        }}
        placement="bottom"
        trigger="click"
      >
        <StackSettingItemStyled direction="row" alignItems="center" spacing={1}>
          <GlobalIcon />
          <CustomTypography>Language</CustomTypography>
        </StackSettingItemStyled>
      </ChangeLanguageWrapper>
    </>
  );
}

export default ChangeLanguage;
